import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Home from './components/home/Home';
import Obituary from './components/obituary';
import Real_heros from './components/real_heros';
import Celebrity from './components/celebrity';
import About from './components/about';
import Privacy_policy from './components/privacy_policy';
import Contact from './components/contact';
import Faq from './components/faq';
import Terms_condition from './components/terms_condition';
import Remembrance from './components/remembrance';
import Join_us from './components/join_us';
import Feedbacks from './components/feedbacks';
import Step1 from './components/StepsForm/step1';
import Selectformat from './components/StepsForm/selectformat';
import SingleLayoutType from './components/StepsForm/single_layout_type';
import MultiLayoutType from './components/StepsForm/multi_layout_type';
import DesignAd from './components/StepsForm/classified_step2';
import DisplaydesignAd from './components/StepsForm/displayad_step2';
import NormaldesignAd from './components/StepsForm/normalad_step2';
import MatterDesign from './components/StepsForm/matter_design';
import Step3 from './components/StepsForm/step3';
import TransactionSummary from './components/StepsForm/transaction_detail';
import BookingSummary from './components/StepsForm/booking_summary';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/header.css";
import "./assets/js/main.js";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {

  constructor(props){
    super(props)
    this.state= {
    }
  }
  componentDidMount(){
    
  } 

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // alert("sdfs");
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }

  render() {
    return this.props.children;
  }
}
  
const Scroll = withRouter(ScrollToTop);

class Root extends React.Component {

render() {
    return(
    <BrowserRouter basename={'/'} >
    <Scroll>
    <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route path="/obituary" component={Obituary} />
        <Route path="/real_heros" component={Real_heros} />
        <Route path="/celebrity" component={Celebrity} />
        <Route path="/about" component={About} />
        <Route path="/remembrance" component={Remembrance} />
        <Route path="/join_us" component={Join_us} />
        <Route path="/feedbacks" component={Feedbacks} />
        <Route path="/contact" component={Contact} />
        <Route path="/faq" component={Faq} />
        <Route path="/privacy_policy" component={Privacy_policy} />
        <Route path="/terms_condition" component={Terms_condition} />
        <Route path="/step1" component={Step1} />
        <Route path="/selectformat" component={Selectformat} />
        <Route path="/single_layout_type" component={SingleLayoutType} />
        <Route path="/multi_layout_type" component={MultiLayoutType} />
        <Route path="/classified_step2" component={DesignAd} />
        <Route path="/displayad_step2" component={DisplaydesignAd} />
        <Route path="/normalad_step2" component={NormaldesignAd} />
        <Route path="/matter_design" component={MatterDesign} />
        <Route path="/step3" component={Step3} />
        <Route path="/transaction_detail" component={TransactionSummary} />
        <Route path="/booking_summary" component={BookingSummary} />
        
    </Switch>
    </Scroll>
    </BrowserRouter>
  );
}
}

ReactDOM.hydrate(<Root />, document.getElementById('root'))
