import React from "react";
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import validator from 'validator'
import axios from "axios";
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Join_us extends React.Component {
  constructor(props){
  super(props)
  this.state={
    Agency_Name:'',
    full_name:'',
    mobile:'',
    email:"",
    Agency_Nameerror:false,
    full_nameerror:false,
    moberror:false,
    emailerror:false,
    submitsuccess:false,
    BannerImg:'',
    BannerContent:'',
    metaKeyword: '',
    metaDescription: '',
    metaTitle: ''
  }
}
componentDidMount(){

  axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'join_us' } }).then(res=>{
    // console.log("dataaaaa-dgfffg-----",res.data)
     this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
     this.setState({BannerContent:res.data[0].description})
   })

   axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 10}}).then(res=>{
    // console.log("dataaaaa------",res.data[0])
    this.setState({metaKeyword: res.data[0].meta_keyword,
    metaTitle:res.data[0].title,
    metaDescription:res.data[0].meta_description})
  })

}

validationform =()=> {
  let isvalid = true
  if(this.state.Agency_Name==''){
    this.setState({Agency_Nameerror:true})
    isvalid=false
  }else if(this.state.Agency_Name!=='' && this.state.Agency_Name.length>3){
    this.setState({Agency_Nameerror:false})
   

  }
  if(this.state.full_name==''){
    this.setState({full_nameerror:true})
    isvalid=false
  }else if(this.state.full_name!=='' && this.state.full_name.length>3 ){
    this.setState({full_nameerror:false})
    

  }
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  if (validator.isEmail(this.state.email)) {
    this.setState({emailerror:false})
    
  }else if(!validator.isEmail(this.state.email)){
    
    this.setState({emailerror:true})
    isvalid=false
  }
  if(this.state.email ===""){
    this.setState({emailerror:true})
    isvalid=false
  }
  
  if(!isNaN(this.state.mobile) ){
    if(this.state.mobile.length<9){
      this.setState({moberror:'please enter 10 digit mobile number'})
    }
    if( this.state.mobile.length===10 ){
      this.setState({moberror:''})
    }
  }else if (isNaN(this.state.mobile)){
    this.setState({moberror:'Mobile number is not valid'})
    isvalid=false
   }
   if(this.state.mobile ===""){
    this.setState({moberror:'Please fill you mobile number'})
    isvalid=false
  }

  if (this.state.mobile !== "undefined") {

    var pattern = new RegExp(/^[0-9\b]+$/);
  
    if (!pattern.test(this.state.mobile)) {
  
      isvalid = false;
      this.setState({moberror:'Please enter only number.'})
     // errors["phone"] = "Please enter only number.";
  
    }else if(this.state.mobile.length != 10){
  
      isvalid = false;
      this.setState({moberror:'Please enter valid phone number.'})
      //errors["phone"] = "Please enter valid phone number.";
  
    }
  }

 
 return isvalid

}

handlechange=(e)=>{

  let name = e.target.name
 // console.log("name:-",e.target.name,"value:-",e.target.value)
  if(name==="Agency_Name"){
    this.setState({Agency_Name:e.target.value})
  }
  if(name==="full_name"){
    this.setState({full_name:e.target.value})

  }
  if(name==="email"){
    this.setState({email:e.target.value})

  }
  if(name==="mobile"){
    this.setState({mobile:e.target.value})
  }
}

handleSubmit=(e)=>{
  e.preventDefault()
  // console.log("validation",this.validationform())
  // alert("stop")
  if(this.validationform()){
  
    const formData = new FormData();
    formData.append('agency_name',this.state.Agency_Name)
    formData.append('name',this.state.full_name)
    formData.append('mobile',this.state.mobile)
    formData.append('email',this.state.email)

           axios({
             method:'post',
             url:API_BASE_URL+`joinus/`,
             data:formData
           }).then(response=>{
            // console.log("response",response)
            // console.log(response.status)
             if(response.status==201){
              this.setState({submitsuccess:true})
              e.target.reset()
              this.setState({Agency_Name:'',full_name:'',mobile:'',email:''})
             }
           })
              
               }
              }
   
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };
 
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>

    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 m-auto">
          <div class="commonHead">
              <h2>Join Us</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera join_us">
            <p>Grow your business by becoming our partner.</p>
            <ul>
              <li>A) Join us without any cost</li>
              <li>B) revenue sharing agreement</li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </section>

     <section className="contact_main">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="join_aside">

              </div>
            </div>

            <div className="col-sm-6 m-auto">
            {this.state.submitsuccess?<p className="successmsg succes_smg" >We've received your message, someone in our team will contact you soon...</p>:null}
              <form onSubmit={this.handleSubmit}  className="contact-form join_form" autocomplete="off">
                <div className="row">
                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Agency Name</label>
                      <input type="text" onChange={this.handlechange}  name="Agency_Name" placeholder="Agency Name*" /> 
                      {this.state.Agency_Nameerror?<p className="validationAlert" >Please enter Agency name</p>:null}
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Your Name</label>
                      <input type="text" onChange={this.handlechange} name="full_name" placeholder="Your Name*" /> 
                      {this.state.full_nameerror ?<p className="validationAlert" >Please enter full name</p>:null}

                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Your Email Id</label>
                      <input type="text" onChange={this.handlechange} name="email" placeholder="Your Email Id*" /> 
                      {this.state.emailerror?<p className="validationAlert" >Please enter valid email address</p>:null}
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Phone No</label>
                      <input type="text"   maxLength={10}  onChange={this.handlechange}  name="mobile" placeholder="Phone No*" /> 
                      
                      {this.state.moberror?<p className="validationAlert" >Please enter valid mobile number</p>:null}

                    </div>
                  </div>

                  <div className="col-sm-12">
                      <div class="form-group set_reg_btn">
                        <button type="submit">Register</button>
                      </div>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <EnquiryForm></EnquiryForm>
      <CommonLogoSlider></CommonLogoSlider>

    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}




