import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from '../Common/tools'
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_2.png";
import dummyImg from '../../assets/img/dummy.png';
import { faBluetooth } from "@fortawesome/free-brands-svg-icons";

var editor
var editor2

export default class DesignAd extends React.Component {
 
  constructor(props){  
    super(props);
    this.state = {
     // actualSize : '',
      colorMode : this.props.location.state.col_mode,
      bookingID: this.props.location.state.bookingId,
      FrameType: this.props.location.state.frameType,
      borderColr: this.props.location.state.borderColor,
      frameBackColr: this.props.location.state.frameColor,
      changeWidth : true,
      displayWidth : '',
      defaultFolder : '',
      pricePera : '',
      //totalPrice : '',
      upload_design: '',
      displayHeight: 10,
     // setminHeight:'',
      hasFocus:false,
      BannerImg:'',
      BannerContent:'',
      whole_width: [],
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  //  this.onImageClick = this.onImageClick.bind(this);
  }

  componentDidMount(){
  
    this.setState({content1:editor.props.data.blocks[0].data.text})
    this.setState({content2:editor2.props.data.blocks[0].data.text})
    
     axios({
      method: 'get',
      url: API_BASE_URL + 'bookprice/?bid='+this.state.bookingID
      }).then(response => { 
         console.log("rate",response.data) 
         this.setState({newsPaperRecord : response.data.paper})
         this.setState({pricePera: response.data.price}) 
        
         const widthArr = response.data.width.split(',');
         // console.log("kdjfhsdkjfhsf",widthArr);
          this.setState({whole_width: widthArr}) 
          const lengthArr = widthArr.length
         // console.log("last value==",widthArr[lengthArr-1])
          this.setState({displayWidth: widthArr[lengthArr-1]})
  
          const widthPX = Math.floor(Number(this.state.displayWidth) * 55)
         // console.log("width====",Math.floor(widthPX))
          this.setState({width_px: widthPX})

          if(this.state.displayWidth <= '5'){
            this.setState({changeWidth: true})
            this.setState({defaultFolder: 1})
          }else{
            this.setState({changeWidth: false})
            this.setState({defaultFolder: 2})
          }

         // this.setState({setWidth:response.data.c_width})
         // this.setState({setHeight:response.data.c_height})
          this.setState({perSizePrice: response.data.per_size})
         // let totalCosting = this.state.pricePera * this.state.setHeight * this.state.setWidth
         // this.setState({costing: totalCosting})
 
         //price calculation :
        
        // let extra_width
        // if(this.state.displayWidth == this.state.setWidth){
        //   extra_width = this.state.displayWidth
        // }else{
        //   extra_width = Math.abs(this.state.displayWidth - this.state.setWidth)
        // }

        // let extra_height
        // if(this.state.displayHeight == this.state.setHeight){
        //   extra_height = this.state.displayHeight
        // }else{
        //   extra_height = Math.abs(this.state.displayHeight - this.state.setHeight)
        // }

        let totalprice = this.state.displayWidth * this.state.displayHeight * this.state.perSizePrice

        const total = parseFloat(totalprice).toFixed(2)
        this.setState({totalPrice : total})   

      }).catch((error) => {
      });

      axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'step2' } }).then(res=>{
        // console.log("dataaaaa-dgfffg-----",res.data)
         this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
         this.setState({BannerContent:res.data[0].description})
      })

  }

  oneditorChange=(e)=>{

  e.saver.save().then((outputData) => {
   // console.log('Article data: ', outputData.blocks[0].data.text)
    this.setState({content1: outputData.blocks[0].data.text})
  }).catch((error) => {
   // console.log('Saving failed: ', error)
  });

  }

  oneditorChange2=(e)=>{

    e.saver.save().then((outputData) => {
     // console.log('Article data: ', outputData.blocks[0].data.text)
      this.setState({content2: outputData.blocks[0].data.text})
    }).catch((error) => {
     // console.log('Saving failed: ', error)
    });

  }

  handleChange(e){

    let height = document.getElementById('dddd').clientHeight;
    let cmHeight = Math.floor( Number(height) * Number(0.017))
    // console.log("height in cm", cmHeight)
    this.setState({displayHeight: cmHeight})

   // console.log("width == ",e.target.value);
    let width = e.target.value
    this.setState({displayWidth : width})

    const widthPX = Math.floor(width * 55)
   // console.log("width====",Math.floor(widthPX))
    this.setState({width_px: widthPX})

    //price calculation :
        
    // let extra_width
    // if(width == this.state.setWidth){
    //   extra_width = width
    // }else{
    //   extra_width = Math.abs(width - this.state.setWidth)
    // }

    // let extra_height
    // if(cmHeight == this.state.setHeight){
    //   extra_height = cmHeight
    // }else{
    //   extra_height = Math.abs(cmHeight - this.state.setHeight)
    // }

    // let totalprice = extra_height * extra_width * this.state.perSizePrice

    // const total = parseFloat(Number(this.state.costing) + Number(totalprice)).toFixed(2)
    // this.setState({totalPrice : total})  

    let totalprice = width * cmHeight * this.state.perSizePrice
    const total = parseFloat(totalprice).toFixed(2)
    this.setState({totalPrice : total})   

    if(width <= '5'){
      this.setState({changeWidth: true})
      this.setState({defaultFolder: 1})
    }else{
      this.setState({changeWidth: false})
      this.setState({defaultFolder: 2})
    }
    
  }

  getHeight=()=>{
    let height = document.getElementById('dddd').clientHeight;
    let cmHeight = Math.floor(Number(height) * Number(0.017))
   // console.log("height in cm2", 5 / 0.017 )
    this.setState({displayHeight : cmHeight})

   //price calculation :
        
  //  let extra_width
  //  if(this.state.displayWidth == this.state.setWidth){
  //    extra_width = this.state.displayWidth
  //  }else{
  //    extra_width = Math.abs(this.state.displayWidth - this.state.setWidth)
  //  }

  //  let extra_height
  //  if(cmHeight == this.state.setHeight){
  //    extra_height = cmHeight
  //  }else{
  //    extra_height = Math.abs(cmHeight - this.state.setHeight)
  //  }

  //  let totalprice = extra_height * extra_width * this.state.perSizePrice

  //  const total = parseFloat(Number(this.state.costing) + Number(totalprice)).toFixed(2)
  //  this.setState({totalPrice : total})  

  let totalprice = this.state.displayWidth * cmHeight * this.state.perSizePrice
  const total = parseFloat(totalprice).toFixed(2)
  this.setState({totalPrice : total}) 

  }

  handleFileChange =(evt) =>{
    evt.preventDefault();
    const formData = new FormData();
    formData.append("design_img",evt.target.files[0]
  );

  formData.append("bid",this.state.bookingID);
    axios({
      method: 'patch',
      url: API_BASE_URL+'booking/',
      data: formData 
      }).then(response => {
       //  console.log("patch",IMG_BASE_URL+response.data.data1.design_img)
       this.setState({upload_design : IMG_BASE_URL+response.data.data1.design_img}) 
       //  console.log(this.state.upload_design)
      })
      .catch((error) => {
      // console.log(error)
      });

  }

  onBack=() =>{
    this.props.history.push({ 
      pathname: '/single_layout_type',
      state: {bookingId: this.state.bookingID}})
  }

  gotoDate=()=>{

    axios({
      method: 'patch',
      url: API_BASE_URL+'booking/',
      data: {
        total_amt : this.state.totalPrice,
        bid : this.state.bookingID,
        size : this.state.displayWidth,
        height_size : this.state.displayHeight,
        content_1 : this.state.content1,
        content_2 : this.state.content2,
        frame_type : this.state.FrameType
      }
      }).then(response => {
       // console.log("patch",response.data)
        if( response.data.msg == 'Booking Done Successfully'){
        this.props.history.push({ 
            pathname: '/step3',
            state: {bookingId: this.state.bookingID}})
        }
        else{
          alert('something went wrong');
        }
      })
      .catch((error) => {
      // console.log(error)
      });

  }

render() {

  const html = `
  <div class='block_ui'>
    <h2>Prayer Meeting</h2>
    <p class="ita_p">you were our shining & guiding light.</p>
  </div>`;

  const html2 = `
  <div class='block_ui'>
    <h4 class="h4_classi">Prithvi</h4>
    <h5 class="h5_classi">it is with deepest sorrow that we inform our beloved.</h5>
    <hr class="edit">
    <p class="p_classi">Passed away peacefully on sunday,march 16th you were full of compation a formidable intellect, and inspired everyone in your orbit to attain a deeper level of humainty.</p>
    <hr class="edit">
    <p class="p_classi">Prayer meeting will be held in the.....sector.....at 4:00PM wednesday,march 19th.</p>
    <h5 class="h5_classi">In Grief</h5>
    <p class="p_classi">Sujay,Shyam Singh, Karan & all Family.</p>
    </div>`;

  // <hr class="edit">
  // <p class="p_classi">A universal mother remembered for her warm generosity, magnetic personality, fearless spirit, unsheakable faith and a life well lived in service to humanity.</p>
  // <hr class="edit">
  // <span>family member</span><br/>
  // <span>family member</span><br/>
  // <span>family members & friends</span><br/>
  // <span>Email: example@gmail.com</span>

  return (
  <main className="mainBg">
    
    <CommonHeader></CommonHeader>

    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>

    <section className="Booking_step1 commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2 className="active">Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                <div className="col-sm-12">
                  <div className="commonHead">
                    <h2>Design your ad</h2>
                    <div className="dash"></div>
                  </div>
                  <div className="color_mode">
                    <h3>Specify Ad Width:</h3> 

                    <div className="radio_btns">
                      {this.state.whole_width.map((data,index)=>{
                       // console.log("dtata",data,index)
                        return( 
                        <>
                          <input type="radio" id={data} value={data} name="width" onClick={(e)=>this.handleChange(e)} defaultChecked={data}/>
                          <label for={data}>{data}cm</label>
                        </>
                        )})}
                    </div>

                  </div>
                </div>
                
               
                <div className="col-sm-6 classified_ad">
                  <div className="design_block_section" onKeyUp={this.getHeight}>
                    <div id="dddd" className={"block_section "+(this.state.changeWidth ? 's_width' : 'l_width')} style={{ width: this.state.width_px+"px",background:this.state.frameBackColr}}>
                      
                      <div className='blockui1'>
                      {editor =  <EditorJs
                          onChange={this.oneditorChange}
                          inlineToolbar={['bold', 'italic','underline','fontSize','Color']}
                          tools={EDITOR_JS_TOOLS}
                          enableReInitialize={false}
                          // tools={EDITOR_JS_TOOLS}
                          data={{
                            time: 1556098174551,
                            blocks: [
                              {
                                type: "paragraph",
                                data: {
                                  text:html,
                                }
                              }
                            ]
                          }} />} 
                      </div>

                      {this.state.upload_design ? 
                      <img src={this.state.upload_design} alt="" />
                      :<img src={dummyImg} alt="" /> } 
                    
                      <div className='blockui2'>
                        {editor2 =  <EditorJs
                           onChange={this.oneditorChange2}
                           inlineToolbar={['bold', 'italic','underline','fontSize','Color']}
                           tools={EDITOR_JS_TOOLS}
                            enableReInitialize={false}
                            // tools={EDITOR_JS_TOOLS}
                            data={{
                            time: 1556098174551,
                            blocks: [
                              {
                                type: "paragraph",
                                data: {
                                  text:html2,
                                }
                              }
                            ]
                        }}/>}
                      </div>
                      <div className="border_corners" style={{borderColor:this.state.borderColr}}>
                        <img className="corner1" src={process.env.PUBLIC_URL + 'frames/classified_frames/'+this.state.colorMode+'/col-'+this.state.FrameType+'/'+1+'.png'} alt="" />
                        <img className="corner2" src={process.env.PUBLIC_URL + 'frames/classified_frames/'+this.state.colorMode+'/col-'+this.state.FrameType+'/'+2+'.png'} alt="" />
                        <img className="corner3" src={process.env.PUBLIC_URL + 'frames/classified_frames/'+this.state.colorMode+'/col-'+this.state.FrameType+'/'+3+'.png'} alt="" />
                        <img className="corner4" src={process.env.PUBLIC_URL + 'frames/classified_frames/'+this.state.colorMode+'/col-'+this.state.FrameType+'/'+4+'.png'} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="col-sm-6 classified_ad">
                  <div className="uploading_section">
                    <div class="wrapper">
                      <h3>Upload Image</h3>
                      <div class="file-upload">
                        <input type="file" id="upload_design" accept="image/png, image/gif, image/jpeg" onChange={this.handleFileChange} />
                      </div>
                    </div>
                    <div className="uploading_notes">
                      <h2>Ad Composing Tips:</h2>
                      <p>For certain ads, supporting documents will be required by the 
                          publication house. You will be contacted for the same (if required)
                          Click on text section in ad layout to edit your text.</p>
                      <p>Click on text section in ad layout to edit your text.</p>
                      <p>Use editor toolbar to enhance your ad matter.</p>
                      <p>Click on preview ad button to preview your actual ad.</p>
                      <p>Click on image to upload your image (if available in ad layout)</p>
                      <p>This is the design only for your reference, which will be published in the newspaper, the design may change.</p>
                      <h3>Documents Required:</h3>
                      <h5>For Recent Death Announcement Ads, publication house requires a 
                          copy of Death Certificate or a Doctors note (Mandatory Requirement).</h5>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 mt-5">
                  <div className="newspaperList">
                    <h5>{this.state.newsPaperRecord}  Rs {this.state.perSizePrice}/sqcm X {this.state.displayWidth}cm (W) X {this.state.displayHeight}cm (H)</h5> 
                    <div className="newsRight">
                      <h4>Rs {this.state.totalPrice}</h4>
                    </div>
                  </div>
                  <div className="date_payment_btns">
                    <button className="back_btn" onClick={this.onBack}>Back</button>
                    <button className="upload_design_btn" onClick={this.gotoDate}>select dates & make payment</button>
                  </div>
                </div>

            </div> 
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  )}
}







