import React from "react";
import card2_img from '../assets/img/card2.png'
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from "axios";
import { API_BASE_URL , IMG_BASE_URL } from "../config/api";
import DocumentMeta from 'react-document-meta';

export default class Obituary extends React.Component {
  constructor(props){
    super(props)
    this.state={
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }

  componentDidMount(){
 
    axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'obituary' } }).then(res=>{
     // console.log("dataaaaa-dgfffg-----",res.data)
      this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
      this.setState({BannerContent:res.data[0].description})
    })

    axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 4 }}).then(res=>{
     // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })

  }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };
  
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 m-auto">
          <div class="commonHead">
              <h2>Obituary Advertisements</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera">
            <p>Obituary ad in newspaper /Tribute in newspaper /Uthala/Antim Ardas/ Cremation advertisement booking in Newspapers in India in any Edition (City) of your choice. We understand how difficult it must be for you to reach or inform out to your friends and family with the news of your loss. We are providing the platform to book obituary/Tribute/Uthala/Antim Ardas/ Cremation advertisement in all national newspapers from your home and the lowest rates. we have more options like Classified display advertisement (width is retracted in 3 cm and height is minimum 5 cm and maximum is your choice till 52 cms) and we do display advertisement no restriction in width (minimum 4 cm x 4 cm and maximum any thinks as you like or as per your need. in both sections (Obituary/ Death Anniversary, Condolence, Baithak/Antim Ardas or Remembrance advertisement etc. Sample design in right site display. We are working with all leading newspaper like Times of India, Hindustan Times, Amar Ujala, Danik Jagra, Hindustan Hindi, Navbhart Times and any national newspaper of India.</p>
            {/* <p>Timestribute.com is Obituary/tribute and rem te.com is Obituary te.com is rem te.com is Obituary te.com is rem te.com is Obituary te.com is rem te.com is Obituary te.com is Obituary te.com is Obituary</p> */}
          </div>
          </div>
          <div className="col-sm-4 obituary_side_img">
          </div>
        </div>
      </div>
    </section>

    <section className="requirement_section">
        <div className="container bg_blur">
            <div className="row">
                <div className="col-sm-5 m-auto text-center">
                    <img src={card2_img} alt="" />
                </div>
                <div className="col-sm-7 m-auto">
                    <div class="commonHeadLight">
                        <h2>Requirements</h2>
                        <div class="dash"></div>
                    </div>
                    <div className="requirement_detail">
                        <h4>Death Certificate</h4>
                        <h4>a photograph</h4>
                        {/* <h4>a photograph of the deceased</h4> */}
                        <h4>a photograph of the deceased</h4>
                        <h4>size of advertisement that you wish to release</h4>
                        <h4>date of release of the advertisement</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}

