import React from "react";
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Faq extends React.Component {
  constructor(props){
    super();
    this.state={
      FAQdata:[],
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }
  componentDidMount(){
    
    axios.get(API_BASE_URL+`faq/`).then(res => {
     this.setState({FAQdata:res.data})
     }).catch(error=>{
      // console.log(error)
     })

     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'faq' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

     axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 18 }}).then(res=>{
     // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })
     
  }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

 
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>

    <section className="faqpage commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <div className="commonHead">
                    <h2>FAQs</h2>
                    <div className="dash"></div>
                </div>
                <div id="accordion">
                  {this.state.FAQdata.map((val,index)=>{
                    return(
                        <div class="card card-faq">
                        
                            <div class="card-header faq_card_design" id={"headingOne" +(val.id)}>
                              <h5 data-toggle="collapse" data-target={"#collapseOne" +(val.id)} aria-expanded="true" aria-controls={"collapseOne" +(val.id)}>
                                  {val.question}
                              </h5>
                            </div>
                      
                            <div id={"collapseOne" +(val.id)} class="collapse show" aria-labelledby={"headingOne" +(val.id)} data-parent="#accordion">
                              <div class="card-body custom_card_body">
                                  {val.answer}
                              </div>
                            </div>
                        </div>
                  )})}
                   
                  </div>
                </div>
            </div>
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main> 
  </DocumentMeta>  
  )}
}




