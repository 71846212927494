import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_1.png";
import DocumentMeta from 'react-document-meta';

export default class Step1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        cityVal: this.props.location.state.id,
        newsVal: this.props.location.state.id1,
        adIdVal: this.props.location.state.id2,
        adTypeVal: this.props.location.state.id3,
        Ad_type:[],
        cityData:[],
        newspaperdata:[],
        offerList:[],
        normalList:[],
        BannerImg:'',
        BannerContent:'',
        metaKeyword: '',
        metaDescription: '',
        metaTitle: ''
    }
     this.handleAdType = this.handleAdType.bind(this);
    }

    componentDidMount(){ 

      axios.get(API_BASE_URL+`enquirycommon/`).then(res => {
         // console.log("response",res.data)
          this.setState({Ad_type:res.data.adtypedata})
          this.setState({cityData:res.data.citydata})
          this.setState({newspaperdata:res.data.newspaperdata})
      })

       axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'ads' } }).then(res=>{
        // console.log("dataaaaa-dgfffg-----",res.data)
         this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
         this.setState({BannerContent:res.data[0].description})
       })

      this.showList()

      axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 7 }}).then(res=>{
       // console.log("dataaaaa------",res.data[0])
        this.setState({metaKeyword: res.data[0].meta_keyword,
        metaTitle:res.data[0].title,
        metaDescription:res.data[0].meta_description})
      })

    }

    showList=()=>{
        axios({
        method: 'get',
        url: API_BASE_URL + 'offers/?cityid='+this.state.cityVal+'&newid='+this.state.newsVal+'&adid='+this.state.adIdVal
        }).then(response => { 
           // console.log("both list",response.data) 
            this.setState({offerList: response.data.data1})
            this.setState({normalList: response.data.data2})
        }).catch((error) => {
        });
    }
    
    handleAdType = (e) => {
        e.preventDefault();
       // window.localStorage.clear();
        this.setState({adTypeVal : e.target.value})
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option =  el.getAttribute('id'); 
        this.state.adIdVal = option
       // console.log("adId======",this.state.adIdVal)
       // console.log("adType======",this.state.adTypeVal)
        this.showList()
    }
    handlePublication = (e) => {
        e.preventDefault();
       // window.localStorage.clear();
        this.state.newsVal = e.target.value
       // console.log("newsid======",this.state.newsVal)
        this.showList()
    }
    handleCity = (e) => {
        e.preventDefault();
        //window.localStorage.clear();
        this.state.cityVal = e.target.value
      //  console.log("cityid======",this.state.cityVal)
        this.showList()
    }

    navigateParam = (offerId,newspaperId) =>{
       // console.log("offerid",this.state.adTypeVal)
      // window.localStorage.clear();
        if(this.state.adTypeVal == '1') {
            this.props.history.push({ 
                pathname: '/matter_design',
                state: { offerIdParam : offerId,
                    publicationIdParam: newspaperId,
                    cityIdParam: this.state.cityVal,
                    adtypeIdParam : this.state.adIdVal
                }
             });
        } else if(this.state.adTypeVal == '4'){
            //alert("normal display")
            this.props.history.push({ 
                pathname: '/normalad_step2',
                state: { offerIdParam : offerId,
                    publicationIdParam: newspaperId,
                    cityIdParam: this.state.cityVal,
                    adtypeIdParam : this.state.adIdVal
                }
             });
        }
        else{
            this.props.history.push({ 
                pathname: '/selectformat',
                state: { offerIdParam : offerId,
                    publicationIdParam: newspaperId,
                    cityIdParam: this.state.cityVal,
                    adtypeIdParam : this.state.adIdVal
                }
             });
        }

    }

render() {

    const meta = {
        title: this.state.metaTitle,
        description: this.state.metaDescription,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: this.state.metaKeyword
          }
        }
      };

    const Ad_type_DATA = this.state.Ad_type.map((val,index)=>{
    return(<>{this.state.adIdVal == val.id ?
            <option key={val.id} value={val.type_of_ads} id={val.id} selected>{val.Ad_type}</option>
            :<option key={val.id} value={val.type_of_ads} id={val.id}>{val.Ad_type}</option>
        }</>)})

    const cityData = this.state.cityData.map((val,index)=>{
    return(<>
    {this.state.cityVal == val.id ?
        <option key={val.id} value = {val.id} selected>{val.city_name}</option>
        :<option key={val.id} value = {val.id}>{val.city_name}</option>
    }
    </>)})

    const newspaperdata =this.state.newspaperdata.map((val,index)=>{
    return(<>
        {this.state.newsVal == val.id ?
         <option key={val.id} value = {val.id} selected> {val.paper_name}</option>
         :<option key={val.id} value = {val.id}> {val.paper_name}</option>
        }
    </>)})

return (
<DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2 className="active">Select Newspaper</h2>
                        <h2>Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                <div className="col-sm-12">
                <form className="contact-form" autocomplete="off">
                    <div className="row">
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                            <label for="city">City</label><br/>
                                <select 
                                name="city"
                                onChange={this.handleCity}
                                required>
                                <option>City</option>
                                {cityData}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="newspaper">News Paper</label><br/>
                                <select 
                                onChange={this.handlePublication}
                                required>
                                <option>News Paper</option>
                                {newspaperdata}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                            <label for="adtype">Ad Type</label><br/>
                                <select 
                                name="adtype"
                                onChange={this.handleAdType}
                                required>
                                <option>Ad Type</option>
                                {Ad_type_DATA}
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
                <div className="col-sm-12">

                {this.state.cityVal && this.state.newsVal && this.state.adIdVal ?
                    <div className="newspaperListSec">
    
                       <h2>Specify Newspaper(s) / Choose a discounted package below : </h2>
      
                        {this.state.offerList.map((data,index)=>{
                        return (
                        <>
                        <div className="newspaperList fix_list">
                            <div className="newsPrice">
                                <div className="logo_news">
                                    <img src={IMG_BASE_URL+data.publication.image} alt="" /> 
                                </div>
                                <div className="circulation_area">
                                    <span>Circulations:</span>
                                    <p>{data.no_of_copies}</p>
                                </div>
                            </div>
                            <div className="newsPrice">
                              <h5>{data.publication.paper_name}</h5>
                              <p className="location_area_p"><b>Covered Area: </b> {data.location_at}</p>
                            </div>
                            <div className="newsPrice">
                                <span>Ad size</span><br/>
                                {this.state.adTypeVal == 1 ?
                                <>
                                 {data.lines == 0 ?  
                                    <p>{data.text_char+' words'}</p>
                                    :<p>{data.text_char+' lines'}</p>
                                 }
                                </>:null
                                }
                                {this.state.adTypeVal == 2 ?
                                    <p>({data.c_width} x {data.c_height} cm)</p>:null
                                }
                                {this.state.adTypeVal == 3 ?
                                    <p>({data.d_width} x {data.d_height} cm)</p>:null
                                }
                                {this.state.adTypeVal == 4 ?
                                    <p>({data.n_width} x {data.n_height} cm)</p>:null
                                }
                            </div>
                            <div className="newsPrice">
                                <span>Ad Rate</span><br/>
                                {this.state.adTypeVal == 1 ?
                                    <p>₹ {data.text_price}/- </p>:null
                                }
                                {this.state.adTypeVal == 2 ?
                                    <p>₹ {data.classified_price}/- Sq cm</p>:null
                                }
                                {this.state.adTypeVal == 3 ?
                                    <p>₹ {data.display_price}/- Sq cm</p>:null
                                }
                                {this.state.adTypeVal == 4 ?
                                    <p>₹ {data.normal_price}/- Sq cm</p>:null
                                }
                            </div>
                            <div className="newsRight">  
                                <div className="newsPrice">
                                    <span>Costing</span><br/>
                                    {this.state.adTypeVal == 1 ?
                                         <p>₹ {data.text_price}/- </p>:null
                                    }
                                    {this.state.adTypeVal == 2 ?
                                         <p>₹ {data.classified_price*data.c_width*data.c_height}/- </p>:null
                                    }
                                    {this.state.adTypeVal == 3 ?
                                         <p>₹ {data.display_price*data.d_width*data.d_height}/- </p>:null
                                    }
                                    {this.state.adTypeVal == 4 ?
                                         <p>₹ {data.normal_price*data.n_width*data.n_height}/- </p>:null
                                    }
                                </div>
                                <button onClick={() =>this.navigateParam(data.id,data.publication.id)}>Book Now</button>
                            </div>
                        </div>
                        </> )})}
                       
                        {this.state.normalList.map((data,index)=>{
                        return (
                        <>
                        <div className="newspaperList fix_list">
                            <div className="newsPrice">
                                <div className="logo_news">
                                    <img src={IMG_BASE_URL+data.publication.image} alt="" /> 
                                </div>
                                <div className="circulation_area">
                                    <span>Circulations:</span>
                                    <p>{data.no_of_copies}</p>
                                </div>
                            </div>
                            <div className="newsPrice">
                                <h5>{data.publication.paper_name}</h5>
                                <p className="location_area_p"><b>Covered Area: </b> {data.location_at}</p>
                            </div>
                            <div className="newsPrice">
                            <span>Ad size</span><br/>
                                {this.state.adTypeVal == 1 ?
                                <>
                                 {data.lines == 0 ?  
                                    <p>{data.text_char+' words'}</p>
                                    :<p>{data.text_char+' lines'}</p>
                                 }
                                </>:null
                                }
                                {this.state.adTypeVal == 2 ?
                                    <p>({data.c_width} x {data.c_height} cm)</p>:null
                                }
                                {this.state.adTypeVal == 3 ?
                                    <p>({data.d_width} x {data.d_height} cm)</p>:null
                                }
                                {this.state.adTypeVal == 4 ?
                                    <p>({data.n_width} x {data.n_height} cm)</p>:null
                                }
                            </div>

                            <div className="newsPrice">
                                <span>Ad Rate</span><br/>
                                {this.state.adTypeVal == 1 ?
                                    <p>₹ {data.text_price}/- </p>:null
                                }
                                {this.state.adTypeVal == 2 ?
                                    <p>₹ {data.classified_price}/- Sq cm</p>:null
                                }
                                {this.state.adTypeVal == 3 ?
                                    <p>₹ {data.display_price}/- Sq cm</p>:null
                                }
                                {this.state.adTypeVal == 4 ?
                                    <p>₹ {data.normal_price}/- Sq cm</p>:null
                                }
                            </div>
                            
                            <div className="newsRight">
                                <div className="newsPrice">
                                    <span>Costing</span><br/>
                                    {this.state.adTypeVal == 1 ?
                                         <p>₹ {data.text_price}/- </p>:null
                                    }
                                    {this.state.adTypeVal == 2 ?
                                         <p>₹ {data.classified_price*data.c_width*data.c_height}/- </p>:null
                                    }
                                    {this.state.adTypeVal == 3 ?
                                         <p>₹ {data.display_price*data.d_width*data.d_height}/- </p>:null
                                    }
                                    {this.state.adTypeVal == 4 ?
                                         <p>₹ {data.normal_price*data.n_width*data.n_height}/- </p>:null
                                    }
                                </div>
                                <button onClick={() =>this.navigateParam(data.id,data.publication.id)}>Book Now</button>
                            </div>
                        </div>
                        </> )})}
                    </div>
                    : <div className="newspaperListSec">
                        <h2>Specify Newspaper(s) / Choose a discounted package below : </h2>
                        <div className="newspaperList fix_list mt-4">
                            <h5>No Data Found</h5>
                        </div>
                      </div>
                      }
                </div>
            </div>
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main> 
  </DocumentMeta>   
  )}
}






