import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_3.png";
import { Calendar } from "react-multi-date-picker"
import DocumentMeta from 'react-document-meta';

var prev_value = ''
export default class Step3 extends React.Component {

    constructor (props) {
        super (props)
        this.state = {
            bookingID: this.props.location.state.bookingId,
            newspaperName: '',
            cityName : '',
            logo:'',
            startDate:'',
            multiplevalue : [],
            prevDateDisabled: '',
            BannerImg:'',
            BannerContent:'',
            newarr:[],
            metaKeyword: '',
            metaDescription: '',
            metaTitle: ''
        }
        this.setValues=this.setValues.bind(this);
    }

    componentDidMount(){

        let newDate = new Date()
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const day = newDate.getDate();
        const c = new Date(year, month, day+1)
        //console.log('datac',c)
        this.setState({ prevDateDisabled: c })

        axios({
         method: 'get',
         url: API_BASE_URL + 'papername/?bid='+this.state.bookingID
         }).then(response => { 
          //  console.log("paper name",response.data) 
            this.setState({newspaperName : response.data.paper_name})
            this.setState({cityName : response.data.city})
            this.setState({logo : IMG_BASE_URL+response.data.serializer[0].image})
            
        }).catch((error) => {
        });

        axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'step3' } }).then(res=>{
          // console.log("dataaaaa-dgfffg-----",res.data)
            this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
            this.setState({BannerContent:res.data[0].description})
        })

        axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 17 }}).then(res=>{
         // console.log("dataaaaa------",res.data[0])
         this.setState({metaKeyword: res.data[0].meta_keyword,
         metaTitle:res.data[0].title,
         metaDescription:res.data[0].meta_description})
        })
   
     }

    setValues=async(data)=>{
      if(!data.length){
       // alert("hello")
        this.setState({newarr: ''})
      }
         
      let subject=[]
      await  this.setState({multiplevalue : data})
        data.map((datedata,index)=>{
         // alert(data.length)
        
          let value = datedata.day+'-'+datedata.month+'-'+datedata.year
          subject.push(value)
          this.setState({newarr: [...subject]})
        })

    }


gotoBookingSummary=()=>{

    const formData = new FormData();
    
    formData.append("bid",this.state.bookingID);
    formData.append("days",this.state.newarr);

      axios({
      method: 'patch',
      url: API_BASE_URL+'booking/',
      data: formData 
      }).then(response => {
      // console.log("patch",response.data)
        if(response.data.msg == 'Booking Done Successfully'){
      
            if(response.data.data1.days != ''){
                this.props.history.push({ 
                pathname: '/booking_summary',
                state: {bookingId: this.state.bookingID}})
            }else{
                alert("please select date")
            }
         
        }
      })
      .catch((error) => {
      // console.log(error)
      });

}

render() {

    const meta = {
      title: this.state.metaTitle,
      description: this.state.metaDescription,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: this.state.metaKeyword
        }
      }
    };

    const MultipleValue=
    this.state.multiplevalue.map((Data,index)=>{
        return(
            <button>
              {Data.day} - {Data.month.number} - {Data.year}
            </button>
             )
        })
        
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2>Compose Ads</h2>
                        <h2 className="active">Dates & Payment</h2>
                    </div>
                </div>
               
                <div className="col-sm-6">
                    <div className="date_sectionLeft date_section">
                        <div className="date_payment_head">
                            <img src={this.state.logo} alt="" />
                            <h3>{this.state.newspaperName} ({this.state.cityName})</h3>
                        </div>
                        <div className="row">
                            {/* <div className="col-sm-6">
                                <h5>Publish ad for: </h5>
                                <div className="inc_dec_add">
                                    <button name="adddata" onClick={this.AddValue} > + </button>
                                    <div className="ad_value"> {this.state.addvalue} </div>
                                    <button name = "subdata" onClick={this.AddValue} > - </button>
                                </div>
                            </div> */}
                            <div className="col-sm-12">
                                <h5>Selected Dates </h5>
                                <div className="selected_dates">
                                   
                                {MultipleValue}       
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div className="col-sm-6">
                  <div  className="date_section">
                        
                        <Calendar 
                        ref={this.calendarRef}
                        multiple={true}
                        onChange={val=>this.setValues(val)}
                        open={true}
                        readOnly={false}
                        value={this.state.startDate}
                        selected={this.state.startDate}
                        minDate={this.state.prevDateDisabled}
                        />
                      
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="date_payment_btns">
                   
                     <button className="upload_design_btn" onClick={this.gotoBookingSummary}>Click to preview your booking details</button>
                   
                  </div>
                </div>

            </div> 
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}



