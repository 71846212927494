import React from "react";
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import CommonHeader from './Common/commonHeader';
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Celebrity extends React.Component {
  state = {
    BannerImg:'',
    BannerContent:'',
    celebritydata:[],
    metaKeyword: '',
    metaDescription: '',
    metaTitle: ''
   }
  componentDidMount() {
     
    axios.get(API_BASE_URL+`celeblist/`).then(res => {
     // console.log("response",res.data)
      this.setState({celebritydata:res.data})
     }).catch(error=>{
      // console.log(error)
     })

     axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 8}}).then(res=>{
      // console.log("dataaaaa------",res.data[0])
       this.setState({metaKeyword: res.data[0].meta_keyword,
       metaTitle:res.data[0].title,
       metaDescription:res.data[0].meta_description})
     })

     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'celebrity' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })
      
  }
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

 const celebritiesdata= this.state.celebritydata.map((val,i)=>{
   if(i%2==0){
   return(
   
     
        <div className="col-sm-6">
          <div className="celebrity_main">
            <div className="cele_bg">
              <div className="celebrity_images">
                <div className="cle_bg">
                  <img className="back_bg" src={IMG_BASE_URL+val.image} alt="Image not found" />
                </div>
                <img className="main_pro_img" src={IMG_BASE_URL+val.image} alt="Image not found" />
              </div>
              <div className="celebrity_name">
                <h2>{val.celebrity_name}</h2>
                <h5>{val.dob + " to " + val.dod}</h5>
              </div>
              <div className="about_celebrity">
                <p>{val.description}</p>
              </div>
            </div>
          </div>
        </div>
        )
 }else{
 return(
   
   
        <div className="col-sm-6">
          <div className="celebrity_main">
            <div className="cele_bg">
              <div className="celebrity_images">
                <img className="back_bg" src={IMG_BASE_URL+val.image} alt="Image not found" />
                <img className="main_pro_img" src={IMG_BASE_URL+val.image} alt="Image not found" />
              </div>
              <div className="celebrity_name">
                <h2>{val.celebrity_name}</h2>
                <h5>{val.dob + " to " + val.dod}</h5>
              </div>
              <div className="about_celebrity">
                <p>{val.description}</p>
              </div>
            </div>
          </div>
        </div>
   )
 }
  })
  //console.log(this.state.celebritydata)
  
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 m-auto">
          <div class="commonHead">
              <h2>Celebrity Remembrance</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera">
            <p>Remembrance is the act of remembering and showing respect for someone who has died or a past event. A memory of something that happened in the past. Celebrity is the fame and public attention accorded by the mass media. Successful careers in sports, entertainment, political leaders and social workers are commonly associated with celebrity status; People may also become celebrities due to media attention on their lifestyle, wealth, or controversial actions, or for their connection to a famous person. Times Tribute portal remembering them and trying to give a real tribute to them.</p>
          </div>
          </div>
        </div>
      </div>
    </section>
    <section className="">
    <div className="container">
    <div className="row">
    {celebritiesdata}
    </div>
    </div>
    </section>
    <EnquiryForm></EnquiryForm>
     <CommonLogoSlider></CommonLogoSlider>
     <Footer></Footer>
  </main>
  </DocumentMeta>  
  )}
}

