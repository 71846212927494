import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import successIcon from '../../assets/img/icons/success.png'

export default class TransactionSummary extends React.Component {

    constructor (props) {
        super (props)
        this.state = {
            bookingID: this.props.location.state.bookingId,
            orderID: this.props.location.state.orderId,
            publication: this.props.location.state.publication,
            city: this.props.location.state.city,
            total_amount: this.props.location.state.totalAmount,
            BannerImg:'',
            BannerContent:'',
        }
        this.goBacktostep1 = this.goBacktostep1.bind(this)
        this.backtohome = this.backtohome.bind(this)
    }

   
    goBacktostep1(){
      // alert("hii")
        this.props.history.replace({ 
        pathname: '/step1',
        state: {  
            id:'',
            id1:'',
            id2:''
        }})
    }

    backtohome(){
        this.props.history.push('/')
    }

render() {
  
  return (
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="Transaction_section">
      <div className="container">
        <div className="row">
            <div className="col-sm-12 transaction_sec1">
              <div className="trans_detail_sec">
                  <img src={successIcon} alt="" />
                <h2>Transaction Successful</h2>
              </div>
               <h5>Thankyou for your purchase !</h5>
               <p>You will receive an order confirmation email with details of your order.</p>
            </div>
            <div className="col-sm-10 m-auto">
                <div className="transaction_sec2">
                    <div className="transs_div">
                        <h2>Transaction Details</h2>
                    </div>
                    <table class="table transs_table table-striped table-hover">
                        <tbody>
                            <tr>
                                <td className="col_width">Order Id</td>
                                <td>{this.state.orderID} </td>
                            </tr>
                            <tr>
                                <td className="col_width">Booking Id</td>
                                <td>{this.state.bookingID}</td>
                            </tr>
                            <tr>
                                <td className="col_width">Publication</td>
                                <td>{this.state.publication} ({this.state.city})</td>
                            </tr>
                            <tr>
                                <td className="col_width">Total Amount</td>
                                <td>Rs. {this.state.total_amount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-sm-12">
            <div className="date_payment_btns">
                <button className="upload_design_btn" onClick={this.goBacktostep1}>Book Another Ad</button>
                <button className="upload_design_btn" onClick={this.backtohome}>Go to Home</button>
            </div>
            </div>
        </div>
      </div>
    </section>
    
   
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  )}
}



