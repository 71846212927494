import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_2.png";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from '../Common/tools'
import dummyImg from '../../assets/img/dummy.png';
import DocumentMeta from 'react-document-meta';

var editor
var editor2

export default class DisplaydesignAd extends React.Component {

  constructor(props){  
    super(props);
    this.state = {
     // actualSize : '',
      block1:'',
      colorMode : this.props.location.state.col_mode,
      bookingID: this.props.location.state.bookingId,
      FrameType: this.props.location.state.frameType,
      activeWidth : '',
      displayWidth : '', 
      defaultFolder : '',
      pricePera : '',
      totalPrice : '',
      upload_design: '',
      displayHeight: 10,
     // setminHeight: '',
      BannerImg:'',
      BannerContent:'',
      whole_width: [],
      width_px:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){

    this.setState({content1:editor.props.data.blocks[0].data.text})
    this.setState({content2:editor2.props.data.blocks[0].data.text})

    axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 16 }}).then(res=>{
     // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })


    axios({
     method: 'get',
     url: API_BASE_URL + 'bookprice/?bid='+this.state.bookingID
     }).then(response => { 
       // console.log("rate",response.data) 
        this.setState({newsPaperRecord : response.data.paper})
        this.setState({pricePera: response.data.price}) 
      
        const widthArr = response.data.width.split(',');
       // console.log("kdjfhsdkjfhsf",widthArr);
        this.setState({whole_width: widthArr}) 
        const lengthArr = widthArr.length
       // console.log("last value==",widthArr[lengthArr-1])
        this.setState({displayWidth: widthArr[lengthArr-1]})

        const widthPX = Math.floor(Number(this.state.displayWidth) * 37.8)
        //console.log("width====",Math.floor(widthPX))
        this.setState({width_px: widthPX})

        if(this.state.displayWidth > 12 || this.state.displayWidth == 12){
          /// this.setState({getColumn: '3 column'})
           this.setState({activeWidth: 'width12'})
           this.setState({defaultFolder: 3})
         }else if(this.state.displayWidth > '9' || this.state.displayWidth == '9'){
         // this.setState({getColumn: '2 column'})
           this.setState({activeWidth: 'width8'})
           this.setState({defaultFolder: 2})
         }else{
         // this.setState({getColumn: '1 column'})
           this.setState({activeWidth: 'width4'})
           this.setState({defaultFolder: 1})
         }

     // this.setState({setWidth:response.data.c_width})
     // this.setState({setHeight:response.data.c_height})
      this.setState({perSizePrice: response.data.per_size})
     // let totalCosting = this.state.pricePera * this.state.setHeight * this.state.setWidth
     // this.setState({costing: totalCosting})

      //price calculation :
        
      // let extra_width
      // if(this.state.displayWidth == this.state.setWidth){
      //   extra_width = this.state.displayWidth
      // }else{
      //   extra_width = Math.abs(this.state.displayWidth - this.state.setWidth)
      // }

      // let extra_height
      // if(this.state.displayHeight == this.state.setHeight){
      //   extra_height = this.state.displayHeight
      // }else{
      //   extra_height = Math.abs(this.state.displayHeight - this.state.setHeight)
      // }

      // let totalprice = extra_height * extra_width * this.state.perSizePrice

      // const total = parseFloat(Number(this.state.costing) + Number(totalprice)).toFixed(2)
      // this.setState({totalPrice : total})  

      let totalprice = this.state.displayWidth * this.state.displayHeight * this.state.perSizePrice

      const total = parseFloat(totalprice).toFixed(2)
      this.setState({totalPrice : total}) 

     }).catch((error) => {
     });

     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'step2' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

 }

  handleChange=(e)=>{
   
    let height = document.getElementById('dddd').clientHeight;
    let cmHeight = Math.floor( Number(height) * Number(0.022))
   // console.log("height in cm", cmHeight)
    this.setState({displayHeight: cmHeight})

   // console.log("width == ",e.target.value);
    let width = e.target.value
    this.setState({displayWidth : width})

    const widthPX = Math.floor(width * 37.8)
   // console.log("width====",Math.floor(widthPX))
    this.setState({width_px: widthPX})

    //price calculation :
        
    // let extra_width
    // if(width == this.state.setWidth){
    //   extra_width = width
    // }else{
    //   extra_width = Math.abs(width - this.state.setWidth)
    // }

    // let extra_height
    // if(cmHeight == this.state.setHeight){
    //   extra_height = cmHeight
    // }else{
    //   extra_height = Math.abs(cmHeight - this.state.setHeight)
    // }

    // let totalprice = extra_height * extra_width * this.state.perSizePrice

    // const total = parseFloat(Number(this.state.costing) + Number(totalprice)).toFixed(2)
    // this.setState({totalPrice : total})  

    let totalprice = width * cmHeight * this.state.perSizePrice
    const total = parseFloat(totalprice).toFixed(2)
    this.setState({totalPrice : total}) 

    if(this.state.displayWidth > 12 || this.state.displayWidth == 12){
    /// this.setState({getColumn: '3 column'})
      this.setState({activeWidth: 'width12'})
      this.setState({defaultFolder: 3})
    }else if(this.state.displayWidth > '9' || this.state.displayWidth == '9'){
   //   this.setState({getColumn: '2 column'})
      this.setState({activeWidth: 'width8'})
      this.setState({defaultFolder: 2})
    }else{
    //  this.setState({getColumn: '1 column'})
      this.setState({activeWidth: 'width4'})
      this.setState({defaultFolder: 1})
    }

  }

  getHeight=()=>{

    let height = document.getElementById('dddd').clientHeight;
    let cmHeight = Math.floor(Number(height) * Number(0.022))
   // console.log("height in cm2",cmHeight)
    this.setState({displayHeight : cmHeight})

    //price calculation :
        
    // let extra_width
    // if(this.state.displayWidth == this.state.setWidth){
    //   extra_width = this.state.displayWidth
    // }else{
    //   extra_width = Math.abs(this.state.displayWidth - this.state.setWidth)
    // }

    // let extra_height
    // if(cmHeight == this.state.setHeight){
    //   extra_height = cmHeight
    // }else{
    //   extra_height = Math.abs(cmHeight - this.state.setHeight)
    // }

    // let totalprice = extra_height * extra_width * this.state.perSizePrice

    // const total = parseFloat(Number(this.state.costing) + Number(totalprice)).toFixed(2)
    // this.setState({totalPrice : total})  

  let totalprice = this.state.displayWidth * cmHeight * this.state.perSizePrice
  const total = parseFloat(totalprice).toFixed(2)
  this.setState({totalPrice : total}) 

  }


  onBack=() =>{
    this.props.history.push({ 
      pathname: '/multi_layout_type',
      state: {bookingId: this.state.bookingID}})
  }

  handleFileChange =(evt) =>{
    evt.preventDefault();

    const formData = new FormData();
    formData.append("design_img",evt.target.files[0]);


  formData.append("bid",this.state.bookingID);
    axios({
      method: 'patch',
      url: API_BASE_URL+'booking/',
      data: formData 
      }).then(response => {
     //  console.log("patch",IMG_BASE_URL+response.data.data1.design_img)
       this.setState({upload_design : IMG_BASE_URL+response.data.data1.design_img}) 
     //  console.log(this.state.upload_design)
      })
      .catch((error) => {
      // console.log(error)
      });

  }

  

  oneditorChange=(e)=>{

    e.saver.save().then((outputData) => {
    // console.log('Article data: ', outputData.blocks[0].data.text)
    // if(this.state.displayHeight > '10'){
      //console.log("hgjhg",outputData.blocks[0].data.text)
    // }
      //console.log("editor--",outputData.blocks[0].data.text)
      //this.setState({editorState: outputData.blocks[0].data.text})
      this.setState({content1: outputData.blocks[0].data.text})
     
    }).catch((error) => {
     // console.log('Saving failed: ', error)
    });
  
  }

  oneditorChange2=(e)=>{

    e.saver.save().then((outputData) => {
     // console.log('Article data: ', outputData.blocks[0].data.text)
    
      this.setState({content2: outputData.blocks[0].data.text})
    
    }).catch((error) => {
     // console.log('Saving failed: ', error)
    });
 
  }
  

  gotoDate=()=>{

    axios({
      method: 'patch',
      url: API_BASE_URL+'booking/',
      data: {
        total_amt : this.state.totalPrice,
        size : this.state.displayWidth,
        height_size : this.state.displayHeight,
        bid : this.state.bookingID,
        content_1 : this.state.content1,
        content_2 : this.state.content2,
        frame_type : this.state.FrameType
      }
      }).then(response => {
       // console.log("patch",response.data)
        if( response.data.msg == 'Booking Done Successfully'){
          this.props.history.push({ 
            pathname: '/step3',
            state: {bookingId: this.state.bookingID}})
        }
        else{
          alert('something went wrong');
        }
      })
      .catch((error) => {
      // console.log(error)
      });

  }
 
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

  const html = `
  <div class='block_ui'>
    <h2>In Loving memory of</h2>
  </div>`;

  const html2 = `
  <div class='block_ui'>
    <p class="p_classi">Poppy/Mann/Mom/Mumma/Daadi you asked for so little but gave us so much love, guidance & blessings. Your beautiful memory will always be with us.whenever you are, we hope you are very happy & at peace. you will always be in our hearts forever.</p>
    <hr class="edit">
    <p class="p_classi">Prayer meeting will be held in the.....sector.....at 4:00PM wednesday,march 19th 2021.</p>
    <hr class="edit">
    <h5 class="h5_classi">Deeply missed & Remembered By:</h5>
    <p class="p_classi">Sujay,Shyam Singh, Karan, Mukesh manoj, Shyamal, mukesh kumar & all Relatives Near & Dear Family members.</p>
    </div>`;

  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2 className="active">Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                <div className="col-sm-12">
                  <div className="commonHead">
                    <h2>Design your ad</h2>
                    <div className="dash"></div>
                  </div>
                  <div className="color_mode">
                    <h3>Specify Ad Width:</h3> 
                    <div className="radio_btns">
                      {this.state.whole_width.map((data,index)=>{
                      // console.log("dtata",data,index)
                        return( 
                        <>
                          <input type="radio" id={data} value={data} name="width" onClick={(e)=>this.handleChange(e)} defaultChecked={data}/>
                          <label for={data}>{data}cm</label>
                        </>
                        )})}
                    </div>
                  </div>
                </div>
                
                <div className="col-sm-6 classified_ad">
                  <div className="design_block_section" onKeyUp={this.getHeight}>
                    <div id="dddd" className={`block_section2 `+this.state.activeWidth} style={{  backgroundImage: `url(${process.env.PUBLIC_URL + 'frames/display_frames/'+this.state.colorMode+'/col-'+this.state.defaultFolder+'/'+this.state.FrameType+'.png'})`, width: this.state.width_px+"px"}}>
                    <div className='blockui1'>
                    {editor =  <EditorJs
                    // editorState={this.state.editorState}
                          onChange={this.oneditorChange}
                          inlineToolbar={['bold', 'italic','underline','fontSize','Color']}
                          tools={EDITOR_JS_TOOLS}
                            enableReInitialize={false}
                            // tools={EDITOR_JS_TOOLS}
                            data={{
                              time: 1556098174501,
                              blocks: [
                                {
                                  type: "paragraph",
                                  data: {
                                    text:html
                                  }
                                }
                              ]
                            }}
                         
                            />} 
                      </div>
                      {this.state.upload_design ? 
                        <img src={this.state.upload_design} alt="" />
                        :<img src={dummyImg} alt="" /> } 

                    <div className='blockui2'>
                      {editor2 =   <EditorJs
                      onChange={this.oneditorChange2}
                        inlineToolbar={['bold', 'italic','underline','fontSize','Color']}
                      tools={EDITOR_JS_TOOLS}
                        enableReInitialize={false}
                        // tools={EDITOR_JS_TOOLS}
                        data={{
                          time: 1556098174501,
                          blocks: [
                            {
                              type: "paragraph",
                              data: {
                                text:html2,
                              }
                            }
                          ]
                        }}/>}
                    </div>
                     
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 classified_ad">
                  <div className="uploading_section">
                    <div class="wrapper">
                      <h3>Upload Image</h3>
                      <div class="file-upload">
                      <input type="file" id="upload_design" accept="image/png, image/gif, image/jpeg" onChange={this.handleFileChange} />
                      </div>
                    </div>
                    <div className="uploading_notes">
                      <h2>Important Tips:</h2>
                      <p>Click on text section in ad layout to edit your text.</p>
                      <p>Use editor toolbar to enhance your ad matter.</p>
                      <p>Click on preview ad button to preview your actual ad.</p>
                      <p>Click on image to upload your image (if available in ad layout)</p>
                      <p>This is the design only for your reference, which will be published in the newspaper, the design may change.</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 mt-5">

                  {/* <div className="newspaperList">
                    <h5>Select Page Preference for your Advertisements ?</h5> 
                    <div className="newsRight">
                      <h4>Rs {this.state.pricePera}/sqcm</h4>
                    </div>
                  </div> */}
                 
                  <div className="newspaperList">
                    <h5>{this.state.newsPaperRecord} Rs {this.state.perSizePrice}/sqcm X {this.state.displayWidth}cm (W) X {this.state.displayHeight}cm (H)</h5> 
                    <div className="newsRight">
                      <h4>Rs {this.state.totalPrice}</h4>
                    </div>
                  </div> 

              {this.state.previewContent ?
                  <div className="date_payment_btns">
                    <button className="back_btn" onClick={this.onBack}>Back</button>
                    <button className="upload_design_btn" onClick={this.previewCost}>Preview Final Cost</button>
                  </div>
              :
                  <div className="date_payment_btns">
                    <button className="back_btn" onClick={this.onBack}>Back</button>
                    <button className="upload_design_btn" onClick={this.gotoDate}>select dates & make payment</button>
                  </div> 
              }
                </div>

            </div> 
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}




