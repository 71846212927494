import React from "react";
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial from './testimonial'
import BookNewsAds from './booknewsAds'
import HomeHeader from '../Common/homeHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import EnquiryDetails from '../Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import DocumentMeta from 'react-document-meta';

export default class Home extends React.Component {
  constructor(props) {  
    super(props); 
    this.state = {
     BannerImg:'',
    logoData:[],
    Ad_type:[],
    cityData:[],
    newspaperdata:[],
    submitSuccessMSG:false,
    backgroundcolor:'',
    fname:'',
    email:'',
    location:'',
    enquirydetail:'',
    details:'',
    publication:'',
    mobile:'',
    nameerror:false,
    emailerror:false,
    locationerror:false,
    enquirydetailerror:false,
    details:false,
    publication:false,
    homeImage : [],
    happyBanner : '',
    metaKeyword: '',
    metaDescription: '',
    metaTitle: ''
  }
  
  this.onChangeHandler = this.onChangeHandler.bind(this);
}


componentDidMount() {

  window.localStorage.clear();

    axios.get(API_BASE_URL+`enquirycommon/`).then(res => {
    // console.log("response",res.data)
    this.setState({logoData:res.data.newspaperdata})
    this.setState({Ad_type:res.data.adtypedata})
    this.setState({cityData:res.data.citydata})
    this.setState({newspaperdata:res.data.newspaperdata})
    })

    axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 5 }}).then(res=>{
      // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })

    axios.get(API_BASE_URL+'contents/?cid='+3).then(res => { 
    //  console.log("response-------",res.data)
      this.setState({homeImage:res.data})
    }) 

    axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'home' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'happy' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
        this.setState({happyBanner:IMG_BASE_URL+res.data[0].image})
      //  this.setState({BannerContent:res.data[0].description})
     })

}

handlechange1=(e)=>{

  window.localStorage.clear();
  
  const id = e.target.name
  if(id==="city"){
  this.setState({cityHC:e.target.value})
 // console.log("city",this.state.cityHC)
  }
  if(id==="newspaper"){
    this.setState({newspaperHC:e.target.value})
  //  console.log("newss",this.state.newspaperHC)
  }

}

onChangeHandler = (e) => {
 
  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index]
  const option =  el.getAttribute('id'); 
  
  this.setState({getAdTypeVal:option})
 // console.log("adtype",this.state.getAdTypeVal) 
  this.setState({adtypeHC:e.target.value})
 // console.log("adid",this.state.adtypeHC) 
 
}

// handleSubmit1 = (event) => {
 
//   console.log("city---",this.state.cityHC)
//   console.log("newss---",this.state.newspaperHC)
//   console.log("adtype---",this.state.getAdTypeVal) 
//   console.log("adid---",this.state.adtypeHC) 

//    event.preventDefault();
//    localStorage.setItem("citySelected",this.state.cityHC)
//    localStorage.setItem("newspaperSelected",this.state.newspaperHC)
//    localStorage.setItem("adtypeSelected",this.state.adtypeHC)
//    localStorage.setItem("adTypeValue",this.state.getAdTypeVal)

//    this.props.history.push('/step1');

// }

handleSubmit1 = (event) => {
 
 // console.log("city---",this.state.cityHC)
 // console.log("newss---",this.state.newspaperHC)
 // console.log("adtype---",this.state.getAdTypeVal) 
 // console.log("adid---",this.state.adtypeHC) 

   event.preventDefault();
  //  localStorage.setItem("citySelected",this.state.cityHC)
  //  localStorage.setItem("newspaperSelected",this.state.newspaperHC)
  //  localStorage.setItem("adtypeSelected",this.state.adtypeHC)
  //  localStorage.setItem("adTypeValue",this.state.getAdTypeVal)   
    this.props.history.replace({ 
    pathname: '/step1',
    state: { id: this.state.cityHC,
    id1:this.state.newspaperHC,
    id2:this.state.adtypeHC,
    id3:this.state.getAdTypeVal }
    });
    
  //  this.props.history.push('/step1');

}

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };
 
  const Ad_type_DATA1 = this.state.Ad_type.map((val,index)=>{
    return <option key = {val.id} id={val.type_of_ads} value={val.id} >{val.Ad_type}</option>
  })
  const cityData1 = this.state.cityData.map((val)=>{
    return <option key = {val.id} value = {val.id}>{val.city_name}</option>
  })
  const newspaperdata1 =this.state.newspaperdata.map((val)=>{
    return <option key = {val.id} value = {val.id}> {val.paper_name}</option>
  })

  const homeImageConst = this.state.homeImage.map((data, index)=>{
    return(
      <div className="col-md-6 col-lg-5 my-auto"  key={index} dangerouslySetInnerHTML={ {__html: data.content} } />
     )})
  

  var settings = {
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2500,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
           
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
   };
  return (
  <DocumentMeta {...meta}>
  <main>
    <EnquiryDetails></EnquiryDetails>
    <HomeHeader></HomeHeader>
    
    {/* <section className="Homebanner CommonSection" style={{backgroundImage: "url("+this.state.BannerImg+")"}}> */}
    <section className="Homebanner CommonSection">
      <div className="container bannerform">
        <div className="row height60vh">
          <div className="col-sm-12 col-md-7 col-lg-5 m-auto">
            <form className="formcard mb-4" onSubmit={(event)=>this.handleSubmit1(event)}>
            <h2 className="advertise_head"> Where would you like to Advertise ?</h2> 
              <div className="form-group input_container">
                <select 
                  onChange={this.handlechange1}
                  name="newspaper"
                  required="required">
                  <option  value="">NewsPaper</option>
                  {newspaperdata1}
                </select>
              </div>
              <div className="form-group input_container">
                <select 
                  name="city"
                  onChange={this.handlechange1}
                  required="required">
                  <option  value="">City</option>
                  {cityData1}
                </select>
              </div>
              <div className="form-group input_container">
                <select 
                onChange={this.onChangeHandler}
                required="required">
                  <option  value="">Ad Type</option>
                  {Ad_type_DATA1}
                </select>
              </div>
              <div className="text-center">
               <button className="submit_btn" type="submit"> Submit </button>
              </div>
            </form>
            
          </div>
          <div className="col-sm-12 col-md-5 col-lg-7 m-auto">
            {/* <div className="headlines">
              <h2>for our departed loved ones</h2>
              <h1>Remember through</h1>
              <h1>NewPapers & through online...</h1>
            </div> */}
            <div className="inner-home-banner">
              <img src={this.state.BannerImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      
      <div className="logoSlider">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings}>
              {this.state.logoData.map((val,index)=>{
                 
                   return(<>
                   {val.logo !==null?
                    <div  className="logo_images">
                      <img className="img-fluid m-auto" src={IMG_BASE_URL + val.logo} alt="Image not Found" />
                    </div>
                    :
                    null
                    }
                   </>)
                  })}
              </Slider>
            </div>
          </div> 
        </div>
      </div>
    
    </section>   

    <section className="product_section commonPg">
       
      <div className="container">
          <div className="row">
            {homeImageConst}
            <div className="col-md-6 col-lg-7 m-auto">
              <img className="img-fluid" src={this.state.happyBanner} alt=""/>
            </div>
          </div>   
      </div>   
     
    </section>

    <Testimonial></Testimonial>

    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <div className="about_pera">
              <h2>What is Timestribute.com</h2>
              <p>Timestribute.com is Obituary/tribute and remembrance advertisement portal which helps us relive the lives of our ancestors and departed loved ones. Here one can upload, store, publish & share biography, family tree, videos, photos, people can post condolence messages, and also receive death anniversary reminders. Times Tribute will provide better platform and economical/chipper rates of all leading newspapers to publish death related and remembrance advertisement. We understand how difficult it must be for you to reach or inform out to your friends and family with the news of your loss. The Silent joy or remembering a loved one. The moments you shared together. Little moments that make life beautiful. Share them with Friends relatives, Neighbors, Colleagues with all those lives the departed Soul has touched. Because while life goes on, when you share memories, they stay on forever.</p>
            </div>
          </div>
          <div className="col-sm-12 col-lg-4 aboutBanner">
          </div>
        </div>
      </div>
    </section>
   <EnquiryForm></EnquiryForm>
   <BookNewsAds></BookNewsAds>
   <Footer></Footer>
  </main> 
  </DocumentMeta> 
  )}
}






