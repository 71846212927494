import React from 'react';
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';

class CommonLogoSlider extends React.Component {

  constructor(props) {  
    super(props); 
    this.state = {
    logoData:[]
    }
  }

  componentDidMount() {

      axios.get(API_BASE_URL+`enquirycommon/`).then(res => {
     // console.log("response",res.data)
      this.setState({logoData:res.data.newspaperdata})
      })

  }

  render() {
    var settings = {
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 2500,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 5
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }]
       };

return (

<section>
<div className="obituary_slider2">
    <div className="container-fluid">
        <div className="row">
        <div className="col-md-12">
            {/* <Slider {...settings}>
            <div className="logo_images">
                <img className="m-auto" src={image1} alt="" />
            </div>
            <div className="logo_images">
                <img className="m-auto" src={image2} alt="" />
            </div>
            <div className="logo_images">
                <img className="m-auto" src={image3} alt="" />
            </div>
            <div className="logo_images">
                <img className="m-auto" src={image4} alt="" />
            </div>
            <div className="logo_images">
                <img className="m-auto" src={image1} alt="" />
            </div>
            </Slider> */}
             <Slider {...settings}>
              {this.state.logoData.map((val,index)=>{
                 
                   return(<>
                   {val.logo !==null?
                    <div  className="logo_images">
                      <img className="img-fluid m-auto" src={IMG_BASE_URL + val.image} alt="Image not Found" />
                    </div>
                    :
                    null
                    }
                   </>)
                  })}
              </Slider>
        </div>
        </div> 
    </div>
    </div>  
</section>
);
}
}

export default CommonLogoSlider