// import Embed from '@editorjs/embed'
// import Table from '@editorjs/table'
// import Paragraph from '@editorjs/paragraph'
// import List from '@editorjs/list'
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
// import LinkTool from '@editorjs/link'
// import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
// import Header from '@editorjs/header'
// import Quote from '@editorjs/quote'
// import Marker from '@editorjs/marker'
// import CheckList from '@editorjs/checklist'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'
import Underline from '@editorjs/underline';
import FontSize from 'editorjs-inline-font-size-tool';
import ColorPlugin from 'editorjs-text-color-plugin'

export const EDITOR_JS_TOOLS = {
    // embed: Embed,
    // table: Table,
    // paragraph: Paragraph,
    // list: List,
    // warning: Warning,
    // code: Code,
    // linkTool: LinkTool,
    // image: Image,
    // raw: Raw,
    // header: Header,
    fontSize: FontSize,
    // quote: Quote,
    // marker: Marker,
    // checklist: CheckList,
    // delimiter: Delimiter,
    // inlineCode: InlineCode,
    // simpleImage: SimpleImage
    underline: Underline,
    Color: {
        class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
        config: {
           colorCollections: ['#FF1300','#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
           defaultColor: '#FF1300',
           type: 'text', 
        }     
      },
    
  }