import React from "react";
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Privacy_policy extends React.Component {

  constructor(props){
    super();
    this.state={
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }
  componentDidMount(){
 
     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'privacy' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

     axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 12}}).then(res=>{
      // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })
     
  }
 
 
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };
 
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="aboutSection commonPg privacy_policy">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 m-auto">
          <div class="commonHead">
              <h2>Privacy & Policy</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera">
            <p className="question">What information do we collect?</p>
            <p>We collect information from you when you register on our site, place an order or fill out a form. While placing order or registering on our site, as appropriate, you may be asked to enter your name, e-mail address or phone number. You may, however, visit our site anonymously.</p>
            <p className="question">What do we use your information for?</p>
            <p>Any of the information we collect from you may be used in one of the following ways:</p>
            <div className="privacy_list">
              <ul>
                <li>To improve customer service (your information helps us respond more effectively to your customer service requests and support needs)</li>
                <li>To send periodic emails the email address you provide may be used to send you information, respond to inquiries, and/or other requests or questions.</li>
                <li>To personalize your experience(your information helps us to better respond to your individual needs)</li>
                <li>To process transactions your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.</li>
                <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you).</li>
              </ul>
            </div>
            <p className="question">How do we protect your information?</p>
            <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order and enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway provider’s database. It is accessible only by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.</p>
            <p className="question">Do we use cookies?</p>
            <p>Yes Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information We use cookies to help us remember and process the ad requests. If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Like most websites, if you turn your cookies off, some of our services may not function properly. However, you can still place your advertisement over the telephone or by contacting customer service.</p>
            <p className="question">Do we disclose any information to outside parties?</p>
            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
            <p className="question">Terms and Conditions</p>
            <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at http://www. timestribute.com/</p>
            <p className="question">Your Consent</p>
            <p>By using our site, you consent to our websites privacy policy.</p>
            <p className="question">Changes to our Privacy Policy</p>
            <p>If we decide to change our privacy policy, we will post those changes on this page.</p>
          </div>
          </div>
        </div>
      </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main> 
  </DocumentMeta> 
  )}
}

