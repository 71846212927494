
import React from 'react';
import Slider from "react-slick"; 
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test1 from "../../assets/img/test.png";
import { API_BASE_URL } from '../../config/api';
import { IMG_BASE_URL } from '../../config/api';

class Testimonial extends React.Component {

  state = {
    TestimonialList: []
  }
  componentDidMount() {
      axios.get(API_BASE_URL+`testimonial/`).then(res => {
        // console.log("testimonial",res.data)
        this.setState({ TestimonialList: res.data });
      })
  }

  render() {
        var settings = {
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 2500,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        };

      const TestimonialListConst = this.state.TestimonialList.map((data) => {
        return (
          <div className="testimonial_slider">
            <div className="profile_sec">
                <img className="testi_image" src={IMG_BASE_URL + data.image} alt="" />
                <div className="testi_content">
                  <h6>{data.testimonial_name}</h6>
                  <span>{data.designation}</span>
                </div>
            </div>
            <div className="testi_content_slide">
                <p>{data.description}</p>
            </div>
          </div>
        )})

    return (
        
    <section className="testimonial_section commonPg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="commonHead">
              <h2>Testimonials</h2>
              <div className="dash"></div>
            </div>
            <p className="testimonial_text">Happy customers are gold for our business, not just because they buy but also because they add value to our marketing strategy. Thanks for given best valuable feedback to boost the credibility of our business and the desirability of our brand online.</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Slider {...settings}>               
              {TestimonialListConst}
            </Slider>
          </div>       
        </div>
      </div>
    </section>  
);
  }
}

export default Testimonial


