import React from "react";
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Terms_condition extends React.Component {

  constructor(props){
    super();
    this.state={
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }
  
  componentDidMount(){
 
     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'terms_condition' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

     axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 11}}).then(res=>{
      //console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })
     
  }
 
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="aboutSection commonPg privacy_policy">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 m-auto">
          <div class="commonHead">
              <h2>Terms & Conditions</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera">
            <p className="question">Disclaimer</p>
            <p>Timestribute.com is not responsible for any wrong/misleading advertisements. Prices of advertisements change from time to time, for which our team is in constant contact with publication houses. Any change in price will be updated on the website. However any differences in the prices, which may need to present on customer can be informed on time.</p>
            <p className="question">Content of Advertisement</p>
            <p>It has been said that advertiser’s material given by him/material of truth and right and is not hidden from newspaper and in compliance with the law of India. The Advertiser’s material said from the material is standing and / legal/civil that can follow the publication of advertisement said that because of any action from the portal website compensation/ newspapers.</p>
            
            <p className="question">Ad Preview</p>
            <p>Ad preview shown to a user is an indicative preview of the ad text only and at times it may differ from the actual published advertisement. Such changes are beyond the control of Timestribute.com and henceforth Timestribute.com is not responsible in any manner.</p>
            <p className="question">Cancellation of Content</p>
            <p>Timestribute.com reserves the exclusive right to cancel any content whatsoever from being published or reflected on its website or in any other mode. The cancellation charges payable to the advertiser shall be at the applicable rates as laid down in the cancellation and refund policy.</p>
            <p className="question">Cancellation Policy and Procedures</p>
            <p>This will be an offline cancellation process and depends upon whether the ad request is already processed / forwarded to the corresponding newspaper for publishing or not. In case cancellation is possible, user needs to send a written communication to customer care team and make a request for ad cancellation.</p>

            <p className="question">Refund policy</p>
            <p>The refund takes 15-20 working days and 5%of the ad rate is deducted.</p>

            <p className="question">Offer, Schemes, Discounts, Services</p>
            <p>Timestribute.com shall reserve the sole discretion and irrevocable right to-offer/not-offer schemes/ services/products/new features/discounts/promotional packages on its web site exclusive to any existing or future media, known or unknown mode and vice versa.</p>
            <p className="question">Limitation of Liability</p>
            <p>Under no circumstances will Timestribute.com be liable for any loss to the advertiser for any error or omission. Cancellations and alterations will be accepted only in writing and before the processing of the request.</p>

            <p className="question">Modifications to Terms and Conditions</p>
            <p>Timestribute.com in its sole discretion shall reserve the right to modify the Terms & Conditions at any time and without prior notice to its users/advertiser, and edit, modify or alter the content of the advertisement.</p>

            <p className="question">Rescheduling Advertisements</p>
            <p>Timestribute.com reserves the unqualified right on behalf of the publication to decline, change or reschedule any advertisement/listing notwithstanding earlier acceptance by us or even if payment for the advertisement has been made. The conditions contained along with its modifications (if any) shall always govern the acceptance and publication of any advertisement.</p>

            <p className="question">Refusal of Service</p>
            <p>If Timestribute.com, in its sole discretion, determines that a violation of the Terms & Conditions have occurred, Timestribute.com may pursue any of its legal remedies, not limited to the cancellation of the user's account and/or the exclusion of any person(s) who may have violated any Terms & Conditions. In sum, Timestribute.com reserves the right to refuse service to anyone at any time, and to remove any listings or any advertisements for any reason, and without notice.</p>

            <p className="question">Taxes and Costs </p>
            <p>User understands and agrees that the User is responsible for all applicable taxes and for all costs that are incurred in using the Timestribute.com service. We may also, in our sole discretion, add or delete fees associated with the Service.</p>

            <p className="question">Disclaimer of Warranties</p>
            <p>User agrees that use of the service is at user's sole risk. The service is provided on an "as is" and on an "as available" basis. Timestribute.com clearly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</p>

            <p className="question">No Warranty of Service</p>
            <p>Timestribute.com makes no warranty that the service will not meet user's requirements, that the service will be uninterrupted, timely, secure, or error free; nor does timestribute.com make any warranty as to the results that may be obtained from the use of the service or as to the accuracy or reliability of any information obtained from the service.</p>

            <p className="question">Governing Law</p>
            <p>These Terms & Conditions and the relationship between User and Timestribute.com shall be governed by the laws of the State of Utter Pradesh, without regard to its conflict of law provisions. User and Timestribute.com agree to submit to the personal and exclusive jurisdiction of the courts located within the State of Uttar Pradesh.</p>

            <p className="question">Filing of Claim</p>
            <p>User agrees that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service must be filed within 15 days. After this duration such claim or cause of action aroused is forever barred.</p>

            <p className="question">Acceptance of Advertisements</p>
            <p>Timestribute.com is accepting the advertisements only on behalf of the publications featured on the site. It is in no way responsible for any delay by the publications to discharge any of their obligations towards the users.</p>

            <p className="question">Reporting Violations of Terms and Conditions</p>
            <p>Please help us keep the Timestribute.com site an enjoyable experience for all Users and make it a one-stop for all media needs of advertisers. If users observe materials or behavior that may violate or are in violation of the Timestribute.com Terms & Conditions, users are requested to email at info@timestribute.com</p>

            <p className="question">Acknowledgement and Acceptance of Terms and Conditions</p>
            <p>The terms and conditions appearing here above consists of the entire agreement between the Party/User (as defined above) and the Company (as defined above) and replaces all previous arrangements/schedules between the parties regarding the subject matter contained hereinabove.</p>

            <p className="question">Personal Services</p>
            <p>Advertisements are accepted in good faith but Timestribute.com does not accept responsibility for their veracity, delay, error, omission in publication, shrinkage in production, compliance with all relevant laws, etc.</p>
            <p>The advertiser and/or publisher indemnifies Timestribute.com from any action or claim brought by any third party arising directly or indirectly from publication or non-publication of an advertisement including but not limited to infringement of any law/statutory requirement etc.</p>
          </div>
          </div>
        </div>
      </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>  
  )}
}

