import React from 'react';
import { API_BASE_URL} from '../../config/api';
import closeIcon from '../../assets/img/query-form.png'
import validator from 'validator'
import axios from "axios";
export default class EnquiryDetails extends React.Component{
    constructor(props){
        super(props)
        this.state={
            default_show:false,
            show90:'',
            Ad_type:[],
            cityData:[],
            newspaperdata:[],
            submitSuccessMSG:false,
            fname:'',
            email:'',
            location:'',
            enquirydetail:'',
            details:'',
            publication:'',
            mobile:'',
            nameerror:false,
            emailerror:false,
            locationerror:false,
            enquirydetailerror:false,
            details:false,
            publication:false,
        }
        this.handlechange=this.handlechange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.removePopup=this.removePopup.bind(this);
    }

    removePopup = ()=>{
     // alert("hii")
      if(this.state.default_show == false){
        this.setState({default_show : true})
      }else{
        this.setState({default_show : false})
      }
    }


    handlechange =(e)=>{
        const id = e.target.id
        if(id === "fname"){
         // console.log("ff",e.target.value)
          this.setState({fname:e.target.value})
        }
        if(id === "email"){
        //  console.log("email",e.target.value.trim())
          this.setState({email:e.target.value.trim()})
        }
       
        if(id === "mobile"){
          this.setState({mobile:e.target.value.trim()})
        }if(id === "publication"){
          this.setState({publication:e.target.value})
        }if(id === "location"){
          this.setState({location:e.target.value})
        }if(id === "enquirydetail"){
          this.setState({enquirydetail:e.target.value})
        }if(id === "details"){
          this.setState({details:e.target.value})
        }
      }
      
      validationform =()=> {
        
        let isvalid = true
        if(this.state.fname==''){
          this.setState({nameerror:true})
          isvalid=false
        } if(this.state.fname!==''){
          this.setState({nameerror:false})
        }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (validator.isEmail(this.state.email)) {
          this.setState({emailerror:false})
          
        }else if(!validator.isEmail(this.state.email)){
          
          this.setState({emailerror:true})
          isvalid=false
        }
        
       
        if(!isNaN(this.state.mobile) ){
        //  console.log("mobile length",this.state.mobile.length)
          if(this.state.mobile.length<10){
            this.setState({moberror:true})
            isvalid=false
          }
          if( this.state.mobile.length===10 ){
            this.setState({moberror:false})
          }
        }else if (isNaN(this.state.mobile)){
          this.setState({moberror:true})
          isvalid=false
         }
         if(this.state.mobile ===""){
          this.setState({moberror:true})
          isvalid=false
        }
        if (this.state.mobile !== "undefined") {

          var pattern = new RegExp(/^[0-9\b]+$/);
        
          if (!pattern.test(this.state.mobile)) {
        
            isvalid = false;
            this.setState({moberror:'Please enter only number.'})
           // errors["phone"] = "Please enter only number.";
        
          }else if(this.state.mobile.length != 10){
        
            isvalid = false;
            this.setState({moberror:'Please enter valid phone number.'})
            //errors["phone"] = "Please enter valid phone number.";
        
          }
        }
        if(this.state.publication==''){
          this.setState({publicationerror:true})
          isvalid=false
        } else if(this.state.publication!==''){
          this.setState({publicationerror:false})
          
        }
        if(this.state.location==''){
          this.setState({locationerror:true})
          isvalid=false
        } else if(this.state.location!==''){
          this.setState({locationerror:false})
          
        }
        if(this.state.enquirydetail==''){
          this.setState({enquirydetailerror:true})
          isvalid=false
        } else if(this.state.enquirydetail!==''){
          this.setState({enquirydetailerror:false})
          
        }
        if(this.state.details==''){
          this.setState({detailserror:true})
          isvalid=false
        } else if(this.state.details!==''){
          this.setState({detailserror:false})
          
        }
      
       return isvalid
      
      }
      
      handleSubmit = (event) => {
        event.preventDefault();
        if(this.validationform()){
        
        const formData = new FormData();
        formData.append('name',this.state.fname)
      
        formData.append('mobile',this.state.mobile)
      
        formData.append('publication',this.state.publication)
      
        formData.append('city',this.state.location)
      
        formData.append('adtype',this.state.enquirydetail)
        formData.append('details',this.state.details)
        formData.append('email',this.state.email)
        
        console.log("formdata",formData)
      
        axios({
          method: 'POST',
          url: API_BASE_URL + "enquiry/",
         
          data:formData
          
        }).then(response => {  
         // console.log("response",response.status)
          if(response.status===200)
          {
           this.setState({submitSuccessMSG:true})
           this.state.mobile=''
           this.state.email=''
           event.target.reset();
      
          }
        }).catch(error=>{
        //  console.log("error",error)
          })
        
      }
      
      }
      
    componentDidMount(){
 

        axios.get(API_BASE_URL+`enquirycommon/`).then(res => {
       // console.log("response",res.data)
       
        this.setState({Ad_type:res.data.adtypedata})
        this.setState({cityData:res.data.citydata})
        this.setState({newspaperdata:res.data.newspaperdata})
        })
    
        setTimeout(() => {
          this.setState({ show90: true });
      }, 0);
       this.handleModal90()
     }
     handleModal90(){
      this.setState({show90:!this.state.show90})
    }
    
    render(){
        const Ad_type_DATA = this.state.Ad_type.map((val,index)=>{
            return   <option key = {val.id} value={val.id}>{val.Ad_type}</option>
          })
          const cityData = this.state.cityData.map((val)=>{
            return <option   key = {val.id} value = {val.id}>{val.city_name}</option>
          })
          const newspaperdata =this.state.newspaperdata.map((val)=>{
            return <option  key = {val.id} value = {val.id}> {val.paper_name}</option>
          })
        return(
    //         <Modal show={this.state.show90} onHide={() => this.handleModal90()} className="all_page_model">

    //         <Modal.Header className="" closeButton>
    //             <div class="enquiryHead">
    //               <h2>Enquiry Now</h2>
    //               <div class="dash"></div>
    //             </div>
    //               </Modal.Header>
    //               <Modal.Body>
    //               <section className="enquiryForm">
    //       <div className="container">
    //         <div class="row">
         
    //         <div class="col-sm-12 m-auto">
    //           <form onSubmit={this.handleSubmit} className="form_enquiry" autocomplete="off">
                
    //             {this.state.submitSuccessMSG?<p className="successmsg succes_smg" >Thank you for contacting us. We will get in touch with you shortly...</p>:null}

    //             <div className="row">

    //               <div class="col-md-12 form-group custom_fields">
    //                 {/* <label for="name">First Name</label> */}
    //                 <input type="name" onChange={this.handlechange} id="fname" placeholder="Full Name" />
    //                 {this.state.nameerror?<p className="validationAlert2" >Please enter name</p>:null}
    //               </div>
    //               <div class="col-md-12 form-group custom_fields">
    //                 {/* <label for="name">Email</label> */}
    //                 <input type="text" onChange={this.handlechange}  id="email" placeholder="Email" />
    //                 {this.state.emailerror?<p className="validationAlert2" >Please enter valid email</p>:null}

    //               </div>
    //               <div class="col-md-12 form-group custom_fields">
    //                 {/* <label for="mobile">Mobile</label> */}
    //                 <input type="text" maxLength={10} onChange={this.handlechange} id="mobile" placeholder="Mobile No" />
    //                 {this.state.moberror?<p className="validationAlert2" >Please enter valid number</p>:null}

    //               </div>
    //               <div class="col-md-12 form-group custom_fields">
    //                 {/* <label for="publication">Publication</label> */}
    //                 <select onChange={this.handlechange} id="publication">
    //                   <option>Publication</option>
    //                   {newspaperdata}
                    
    //                 </select>
    //               {this.state.publicationerror?<p className="validationAlert2" >Please select publication</p>:null}
    //               </div>
    //               <div class="col-md-12 form-group custom_fields">
    //                 {/* <label for="location">Location/City</label> */}
    //                 <select onChange={this.handlechange} id="location">
    //                   <option>Location</option>

    //                   {cityData}
    //                 </select>
    //                 {this.state.locationerror?<p className="validationAlert2" >Please select city</p>:null}

    //               </div>
    //               <div class="col-md-12 form-group custom_fields">
    //                 {/* <label for="enquirydetail">Enquiry Details</label> */}
    //                 <select onChange={this.handlechange} id="enquirydetail">
    //                   <option>Ad Type</option>
                     
    //                   {Ad_type_DATA}

    //                 </select>
    //                 {this.state.enquirydetailerror?<p className="validationAlert2" >Please select type of advertisement</p>:null}

    //               </div>
    //             </div>
    //             <div class="form-group custom_fields">
    //               {/* <label for="details">Details</label> */}
    //               <textarea   onChange={this.handlechange} placeholder="Your Details" id="details" rows="3"></textarea>
    //               {this.state.detailserror?<p className="validationAlert2" >Please add some more information</p>:null}
    //             </div>
    //             <div className="text-center">
    //               <button>Submit now</button>
    //             </div>
    //           </form>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    //         </Modal.Body>
    //         </Modal>

    <div className={"popup-container " +(this.state.default_show ? 'show-popup' : 'hide-popup')}>
      <div className="close-section" onClick={this.removePopup}>
        <img src={closeIcon} alt="" />
      </div>
      <div className="main-popup">
        <div className="popup_header">
          <h2>Enquiry Now</h2>
        </div>
        <div className="popup-body">
            <form onSubmit={this.handleSubmit} className="" autocomplete="off">
                
                {this.state.submitSuccessMSG?<p className="successEnquiry_msg" >Thank you for contacting us. We will get in touch with you shortly...</p>:null}
            
                <div className="popup-content">
            
                  <div class="">
                    {/* <label for="name">First Name</label> */}
                    <input type="name" onChange={this.handlechange} id="fname" placeholder="Full Name" />
                    {this.state.nameerror?<p className="validationAlert2" >Please enter name</p>:null}
                  </div>
                  <div class="">
                    {/* <label for="name">Email</label> */}
                    <input type="text" onChange={this.handlechange}  id="email" placeholder="Email" />
                    {this.state.emailerror?<p className="validationAlert2" >Please enter valid email</p>:null}
            
                  </div>
                  <div class="">
                    {/* <label for="mobile">Mobile</label> */}
                    <input type="text" maxLength={10} onChange={this.handlechange} id="mobile" placeholder="Mobile No" />
                    {this.state.moberror?<p className="validationAlert2" >Please enter valid number</p>:null}
            
                  </div>
                  <div class="">
                    {/* <label for="publication">Publication</label> */}
                    <select onChange={this.handlechange} id="publication">
                      <option>Publication</option>
                      {newspaperdata}
                                
                    </select>
                  {this.state.publicationerror?<p className="validationAlert2" >Please select publication</p>:null}
                  </div>
                  <div class="">
                    {/* <label for="location">Location/City</label> */}
                    <select onChange={this.handlechange} id="location">
                      <option>Location</option>
            
                      {cityData}
                    </select>
                    {this.state.locationerror?<p className="validationAlert2" >Please select city</p>:null}
                  </div>
                  <div class="">
                    {/* <label for="enquirydetail">Enquiry Details</label> */}
                    <select onChange={this.handlechange} id="enquirydetail">
                      <option>Ad Type</option>
                                 
                      {Ad_type_DATA}
            
                    </select>
                    {this.state.enquirydetailerror?<p className="validationAlert2" >Please select type of advertisement</p>:null}
            
                  </div>

                  <div class="">
                    {/* <label for="details">Details</label> */}
                    <textarea   onChange={this.handlechange} placeholder="Your Details" id="details" rows="3"></textarea>
                    {this.state.detailserror?<p className="validationAlert2" >Please add some more information</p>:null}
                  </div>

                    <div className="text-center">
                      <button>Submit now</button>
                    </div>
                </div>
                
              </form>
        </div>
      </div>
    </div>
        )
        }}
    