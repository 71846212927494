import React from "react";
import CommonHeader from './Common/commonHeader'
import Footer from './Common/footer'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import EnquiryDetails from './Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Remembrance extends React.Component {

state = {
  herodata:[],
  BannerImg:'',
  BannerContent:'',
  metaKeyword: '',
  metaDescription: '',
  metaTitle: ''
}

componentDidMount() {
   
 this.herolistfun()

 axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'hero' } }).then(res=>{
  // console.log("dataaaaa-dgfffg-----",res.data)
   this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
   this.setState({BannerContent:res.data[0].description})
 })

 axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 6 }}).then(res=>{
 // console.log("dataaaaa------",res.data[0])
  this.setState({metaKeyword: res.data[0].meta_keyword,
  metaTitle:res.data[0].title,
  metaDescription:res.data[0].meta_description})
})

}

herolistfun =()=>{

  axios.get(API_BASE_URL+`herolist/`).then(res => {
    this.setState({herodata:res.data})
   
   }).catch(error=>{
    // console.log(error)
   })
 
}

heroDatafun = (herodata) => {

  //console.log("herodata",herodata)
  herodata.map((val,index) => {
   // console.log("ssss")
     this.setState({heroName:val.hero_name,
 heroDob:val.dob,
 heroDod:val.dod,
                    herodis:val.description,
                    heroimage:val.image})
})
}

render() {
// console.log(this.state.heroName,
 // this.state. herodis,
 // this.state.heroimage)

 const meta = {
  title: this.state.metaTitle,
  description: this.state.metaDescription,
  meta: {
    charset: 'utf-8',
    name: {
      keywords: this.state.metaKeyword
    }
  }
};
  
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 m-auto">
          <div class="commonHead">
              <h2>Tribute To Real Heros</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera">
            <p>A Real hero is a real person or a main fictional character of a literary work. Who in the face of danger, battle worriment through feats of simplicity, daring, or strength; they are the original hero. Who perform great deeds or selfless acts for the common good instead of the goal of wealth, pride, and fame. Times Tribute is dedicated this page to our real heroes how was live to our Country and die to Country. Times Tribute portal Salute to our real heroes and trying to give a real tribute to them.</p>
          </div>
          </div>
        </div>
      </div>
    </section>
{ this.state.herodata.map((val,index,hh) => {
  if (index%2==0){
    return(
    <section className="about_heros">
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <img className="heros_img" src={IMG_BASE_URL + val.image} alt="" />
          </div>
          <div className="col-sm-8 mt-auto">
            <div className="heros_detail">
              <h2>{val.hero_name}</h2>
              <h5>{val.dob}</h5>
              <p>{val.description}</p> 
            </div>
          </div>
        </div>
      </div>
    </section>
    )
  }
   if (index%2!=0){
    return(
    <section className="about_heros mr-bottom-50 mr-top-50">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 mt-auto">
            <div className="heros_detail">
            <h2>{val.hero_name}</h2>
              <h5>{val.dob}</h5>
              <p>{val.description}</p>           
               </div>
          </div>
          <div className="col-sm-4">
          <img className="heros_img" src={IMG_BASE_URL + val.image} alt="" />
          </div>
        </div>
      </div>
    </section>
    )
  }
   })}

<EnquiryForm></EnquiryForm>
<CommonLogoSlider></CommonLogoSlider>
<Footer></Footer>
  </main> 
  </DocumentMeta> 
  )}
}

