import React from "react";
import axios from 'axios';
import { API_BASE_URL } from '../../config/api';
import { withRouter } from 'react-router';

 class BookNewsAds extends React.Component {

  constructor(props) {  
    super(props); 
    this.state = {
        adType: [],
        cityType: [],
        newspaperType: []
    }
  }
    componentDidMount() {
       
        axios.get(API_BASE_URL+`enquirycommon/`).then(res => {
          //  console.log("common-----",res.data)
            this.setState({ adType: res.data.adtypedata });
            this.setState({ cityType: res.data.citydata });
            this.setState({ newspaperType: res.data.newspaperdata });
        })
    }

    // paperNav(id){
    //  // alert(id)
    //   localStorage.setItem("newspaperSelected",id)
    //   this.props.history.push('/step1');
    // }
    // cityNav(id){
    //  // alert(id)
    //   localStorage.setItem("citySelected",id)
    //   this.props.history.push('/step1');
    // }
    // adNav(id){
    //  // alert(id)
    //   localStorage.setItem("adtypeSelected",id)
    //   this.props.history.push('/step1');
    // }

    paperNav(id){
     // alert(id)
      this.props.history.replace({ 
       pathname: '/step1',
       state: {
         id:'',
       id1:id,
       id2:''
       }})
   
       // localStorage.setItem("newspaperSelected",id)
       // this.props.history.push('/step1');
     }
     cityNav(id){
     // alert(id)
      this.props.history.replace({ 
       pathname: '/step1',
       state: { id: id,
         id1:'',
         id2:'',
         id3:''
       }})
       // localStorage.setItem("citySelected",id)
       // this.props.history.push('/step1');
     }
     adNav(id,adtype){
    //  alert(id)
      this.props.history.replace({ 
       pathname: '/step1',
       state: {  id2:id,
         id:'',
         id1:'',
         id3: adtype
       }})
       // localStorage.setItem("adtypeSelected",id)
       // this.props.history.push('/step1');
     }
 
render() {

  return (
    <section className="newspaperSection commonPg">
      <div className="container">
        <div class="row">
          <div class="col-md-12">
            <div class="commonHead">
              <h2>Book Newspaper Ads Through</h2>
              <div class="dash"></div>
            </div>
            <ul id="tabs" class="nav nav-tabs justify-content-start border-0" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#menu1">Newspapers</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#menu2">City</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#menu3">Ad Type</a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="menu1" class="tab-pane active">
                <div class="row">
                 
                  {this.state.newspaperType.map((data) => {
                    return ( 
                      <div className="col-6 col-md-3 NewspaperList" onClick={()=>this.paperNav(data.id)}>
                        {data.paper_name}
                      </div>
                   
                    )})}
                 
                  </div>
               
              </div>
              <div id="menu2" class="tab-pane fade">
                <div class="row">
                
                {this.state.cityType.map((data) => {
                    return ( 
                      <div className="col-6 col-md-3 NewspaperList" onClick={()=>this.cityNav(data.id)}>
                      {data.city_name}
                      </div>
                  
                )})}
                  </div>
                </div>
             
              <div id="menu3" class="tab-pane fade">
                <div class="row">

                {this.state.adType.map((data) => {
                    return ( 
                      <div className="col-6 col-md-3 NewspaperList" onClick={()=>this.adNav(data.id, data.type_of_ads)}>
                      {data.Ad_type}
                      </div>
                 
                )})}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )}
}

export default withRouter(BookNewsAds);


