import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import singleCol from '../../assets/img/single.png'
import multiCol from '../../assets/img/multi.png'
import stepBackground from "../../assets/img/step_1.png";
import validator from 'validator'
import DocumentMeta from 'react-document-meta';

 class Selectformat extends React.Component {
  constructor (props) {
    super (props)
    this.state = {
      offerId: this.props.location.state.offerIdParam,
      publicationId: this.props.location.state.publicationIdParam,
      cityId: this.props.location.state.cityIdParam,
      adtypeId: this.props.location.state.adtypeIdParam,
      ContactName: '',
      ContactMobile: '',
      ContactEmail: '',
      formatType: '',
     // RateList: [],
      classifiedPrice: '',
      displayPrice:'',
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectAnotherType =  this.selectAnotherType.bind(this);
  }

  handleChangeName = (evt) =>{
    this.setState({ ContactName: evt.target.value });
  }
  handleChangeMobile= (evt) =>{
    this.setState({ ContactMobile: evt.target.value });
  }
  handleChangeEmail= (evt) =>{
    this.setState({ ContactEmail: evt.target.value });
  }

  formatType = (evt) =>{
    this.setState({ formatType: evt.target.value });
   // console.log("ad type",this.state.formatType)
  }
  selectAnotherType = () =>{
   alert('please select another package & proceed')
  }

  validationform =()=> {
    let isvalid = true
  
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (validator.isEmail(this.state.ContactEmail)) {
      this.setState({emailerror:false})
    }else{ 
      this.setState({emailerror:true})
      isvalid=false
    }

    if(!isNaN(this.state.ContactMobile) ){
      if(this.state.ContactMobile.length<9){
        this.setState({moberror:'please enter 10 digit mobile number'})
      }
      if( this.state.ContactMobile.length===10 ){
        this.setState({moberror:''})
      }
    }else if (isNaN(this.state.ContactMobile)){
      this.setState({moberror:'Mobile number is not valid'})
      isvalid=false
     }
     if(this.state.ContactMobile ===""){
      this.setState({moberror:'Please fill you mobile number'})
      isvalid=false
    }

    if (this.state.ContactMobile !== "undefined") {

      var pattern = new RegExp(/^[0-9\b]+$/);
    
      if (!pattern.test(this.state.ContactMobile)) {
    
        isvalid = false;
        this.setState({moberror:'Please enter only number.'})
       // errors["phone"] = "Please enter only number.";
    
      }else if(this.state.ContactMobile.length != 10){
    
        isvalid = false;
        this.setState({moberror:'Please enter valid phone number.'})
        //errors["phone"] = "Please enter valid phone number.";
    
      }
    }
  
    return isvalid 
  }

  componentDidMount(){
  
      axios({
        method: 'get',
        url: API_BASE_URL + 'basicrate/?offerid='+this.state.offerId
        }).then(response => { 
           /// console.log("basic rates",response.data.rate) 
           // this.setState({RateList:response.data.rate}) 
            let classified_price = response.data.rate[0].c_width*response.data.rate[0].c_height*response.data.rate[0].classified_price
           // console.log("price: ", classified_price)
            this.setState({classifiedPrice : classified_price})
            let display_price = response.data.rate[0].d_width*response.data.rate[0].d_height*response.data.rate[0].display_price
           // console.log("price: ", display_price)
            this.setState({displayPrice : display_price})
        }).catch((error) => {
        });

        axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'select_format' } }).then(res=>{
          // console.log("dataaaaa-dgfffg-----",res.data)
           this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
           this.setState({BannerContent:res.data[0].description})
        })

        axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 13 }}).then(res=>{
          console.log("dataaaaa------",res.data[0])
          this.setState({metaKeyword: res.data[0].meta_keyword,
          metaTitle:res.data[0].title,
          metaDescription:res.data[0].meta_description})
        })
      
  }
  
  handleSubmit = (event)=>{

    event.preventDefault();
    if(this.validationform()){
    axios({
      method: 'post',
      url: API_BASE_URL+'booking/',
      data: {
          offer : this.state.offerId,
          publication: this.state.publicationId,
          city: this.state.cityId,
          adtype: this.state.adtypeId,
          types_of_ad: this.state.formatType,
          contact_name: this.state.ContactName,
          contact_email: this.state.ContactEmail,
          contact_mobile: this.state.ContactMobile, 
      }

      }).then(response => {
        // console.log("post----",response.data)
    
      if(response.data.msg == 'Booking Done Successfully'){
        if(this.state.formatType == "2"){

          this.props.history.push({ 
            pathname: '/single_layout_type',
            state: { 
              bookingId: response.data.data1.id,
              offerId : this.state.offerId
            }
          });

        }else{

          this.props.history.push({ 
            pathname: '/multi_layout_type',
            state: {
                    bookingId: response.data.data1.id,
                    offerId : this.state.offerId,
                    // publicationId: this.state.publicationId,
                    // cityId: this.state.cityId,
                    // adtypeId : this.state.adtypeId
                  }
          });

        }
      }
      })
      .catch((error) => {
      // console.log(error)
      });
    }
  }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2 className="active">Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                <form autocomplete="off" onSubmit={this.handleSubmit}> 
                <div className="col-sm-12">
                  <div className="contact-form">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="commonHead">
                            <h2>Provide Your Contact Details</h2>
                            <div className="dash"></div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="name">Name</label><br/>
                                <input type="text" name="name" value={this.state.ContactName} onChange={this.handleChangeName} placeholder="Name" required/> 
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="mobile">Mobile</label><br/>
                                <input type="text" maxlength="10" minlength="10"  name="Mobile" value={this.state.ContactMobile} onChange={this.handleChangeMobile} placeholder="Mobile" required/> 
                                {this.state.moberror?<p className="validationAlert" >Please enter valid number</p>:null}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="email">Email</label><br/>
                                <input type="text" name="email" value={this.state.ContactEmail} onChange={this.handleChangeEmail} placeholder="Email" required/> 
                                {this.state.emailerror?<p className="validationAlert" >Please enter valid email</p>:null}
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-4">
                  <div className="commonHead">
                    <h2>Select Ad Format</h2>
                    <div className="dash"></div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 classified_ad">
                      <h3>Classifield ad</h3>
                      <div className="format_design">
                        <h5 className="color_text">Single Column</h5>
                        <img src={singleCol} alt="" />
                        
                          <h3>Price : ₹ {this.state.classifiedPrice + "/-"}</h3>
                          
                        {this.state.classifiedPrice !== 0 ?
                        <button value="2" onClick={this.formatType}  type="submit">Select & Book</button>
                        :<button onClick={this.selectAnotherType} >Select & Book</button>
                        }
                        </div>
                    </div>
                    <div className="col-sm-6 classified_ad">
                      <h3>Display ad</h3>
                      <div className="format_design">
                        <h5 className="color_text">Multi Column</h5>
                        <img src={multiCol} alt="" />
                        <h3>Price : ₹ {this.state.displayPrice + "/-"}</h3>
                          
                        {this.state.displayPrice !== 0 ?
                        <button value="3" onClick={this.formatType}  type="submit">Select & Book</button>
                        :<button onClick={this.selectAnotherType} >Select & Book</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div> 
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}

export default Selectformat


