import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import single1 from '../../assets/img/color_images/single1.png'
import single2 from '../../assets/img/color_images/single2.png'
import single3 from '../../assets/img/color_images/single3.png'
import singleB_W1 from '../../assets/img/black_white/single1.png'
import singleB_W2 from '../../assets/img/black_white/single2.png'
import singleB_W3 from '../../assets/img/black_white/single3.png'
import stepBackground from "../../assets/img/step_1.png";
import DocumentMeta from 'react-document-meta';

export default class SingleLayoutType extends React.Component {

  constructor(props) {  
    super(props); 
    this.state = {
      bookingID : this.props.location.state.bookingId,
      offerID : this.props.location.state.offerId,
      colorMode : '1',
      upload_design: null,
      BannerImg:'',
      BannerContent:'',
      pricePera:'',
      totalAmount:'',
      displayWidth : '',
      displayHeight: 5,
      whole_width: [],
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.gotoEditDesign = this.gotoEditDesign.bind(this);
    this.handledWidth = this.handledWidth.bind(this);
    this.handledHeight = this.handledHeight.bind(this);
}

  handleChange(e){
   this.setState({colorMode: e.target.value})
  }

  handleFileChange(evt) {

    this.setState({upload_design: evt.target.files[0]})
    // console.log("hello",evt.target.files[0].name)
    if(evt.target.files[0]){
      this.setState({imageSelected: evt.target.files[0].name})
    }

  }

  handleSubmit = (e)=>{
    e.preventDefault();
  }

  componentDidMount(){

     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'layout_type' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

     axios({
      method: 'get',
      url: API_BASE_URL + 'bookprice/?bid='+this.state.bookingID
      }).then(response => { 
        const widthArr = response.data.width.split(',');
       //  console.log("kdjfhsdkjfhsf",widthArr);
         this.setState({whole_width: widthArr}) 
         this.setState({displayWidth: widthArr[0]}) 
      }).catch((error) => {
      });

     axios({
      method: 'get',
      url: API_BASE_URL + 'basicrate/?offerid='+this.state.offerID
      }).then(response => { 
       // console.log("basic rates",response.data.rate[0]) 
        /// this.setState({pricePera:response.data.rate[0].classified_price})
        this.setState({newsPaperRecord : response.data.rate[0].publication.paper_name})
        this.setState({perSizePrice: response.data.rate[0].classified_per_size})
        let totalprice = this.state.displayWidth * this.state.displayHeight * this.state.perSizePrice
        const total = parseFloat(totalprice).toFixed(2)
        this.setState({totalAmount : total}) 

      }).catch((error) => {
      });

      axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 14 }}).then(res=>{
        // console.log("dataaaaa------",res.data[0])
        this.setState({metaKeyword: res.data[0].meta_keyword,
        metaTitle:res.data[0].title,
        metaDescription:res.data[0].meta_description})
      })

  }

  handledWidth(e){
    let width = e.target.value
    this.setState({displayWidth : width})
    //const total = parseFloat(this.state.displayHeight * width * this.state.pricePera).toFixed(2)  
    // console.log("totalp",total)
    //this.setState({totalAmount : total}) 
    let totalprice = width * this.state.displayHeight * this.state.perSizePrice
    const total = parseFloat(totalprice).toFixed(2)
    this.setState({totalAmount : total}) 
   
   }

   handledHeight(e){
    let height = e.target.value
    this.setState({displayHeight : height})
   // const total = parseFloat(height * this.state.displayWidth * this.state.pricePera).toFixed(2)  
    // console.log("totalp",total)
    //this.setState({totalAmount : total}) 
    let totalprice = this.state.displayWidth * height * this.state.perSizePrice
    const total = parseFloat(totalprice).toFixed(2)
    this.setState({totalAmount : total}) 
   
   }
  

  gotoEditDesign=(designtype,frame_type,border_color,frame_color)=>{

     // console.log(designtype)

     if(designtype == 0){

      axios({
        method: 'patch',
        url: API_BASE_URL+'booking/',
        data: {
          bid : this.state.bookingID,
          color_mode: this.state.colorMode}
        }).then(response => {
         // console.log("post",response.data)
          if(response.data.msg == 'Booking Done Successfully'){
            this.props.history.push({ 
              pathname: '/classified_step2',
              state: {bookingId: this.state.bookingID,
                  col_mode: this.state.colorMode,
                 frameType: frame_type,
                borderColor: border_color,
                frameColor: frame_color
              }})
          }else{
            alert("something went wrong")
          }
        })
        .catch((error) => {
        // console.log(error)
        }); 
      }else{
        const formData = new FormData();
        formData.append("image",this.state.upload_design);
        formData.append("bid",this.state.bookingID);
        formData.append("color_mode",this.state.colorMode);
        formData.append("total_amt",this.state.totalAmount);
        formData.append("size", this.state.displayWidth);
        formData.append("height_size", this.state.displayHeight);

        axios({
          method: 'patch',
          url: API_BASE_URL+'booking/',
          data: formData 
          }).then(response => {
            // console.log("patch",response.data)
            if(response.data.msg == 'Booking Done Successfully'){
               this.props.history.push({ 
                pathname: '/step3',
                state: {bookingId: this.state.bookingID}})
            }else{
              alert("please upload design")
            }
          })
          .catch((error) => {
          // console.log(error)
          });
      }

    }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
          <form autocomplete="off" onSubmit={this.handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2 className="active">Select Newspaper</h2>
                        <h2>Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                <div className="col-sm-12">
                  <div className="commonHead">
                    <h2>Select Ad Layout Below</h2>
                    <div className="dash"></div>
                  </div>
                  <div className="color_mode">
                    <h3>Select Color Mode:</h3> 
                    <div className="radio_btns">
                      <input type="radio" id="color" name="colormode" value="1" onChange={this.handleChange} checked={this.state.colorMode=="1"}/>
                      <label for="color">Color Ad</label>
                      <input type="radio" id="b_w" name="colormode" value="0" onChange={this.handleChange} />
                      <label for="b_w">B/W Ad</label>
                    </div>
                  </div>
                </div>
                {this.state.colorMode == "1" ?
                <>
                <div className="col-sm-4 classified_ad">
                  <div className="format_design highlight_design">
                    <img src={single1} alt="" />
                    <button onClick={()=>this.gotoEditDesign(0,1,'#663f08','#faf7f6')}>Select Design</button>
                  </div>
                </div>
                <div className="col-sm-4 classified_ad">
                  <div className="format_design">
                    <img src={single2} alt="" />
                    <button onClick={()=>this.gotoEditDesign(0,2,'#e4c064','#fbf8f8')}>Select Design</button>
                  </div>
                </div>
                <div className="col-sm-4 classified_ad">
                  <div className="format_design">
                    <img src={single3} alt="" />
                    <button onClick={()=>this.gotoEditDesign(0,3,'#e4c064','#fff1df')}>Select Design</button>
                  </div>
                </div>
                </> 
              : <>
                <div className="col-sm-4 classified_ad">
                  <div className="format_design highlight_design">
                    <img src={singleB_W1} alt="" />
                    <button onClick={()=>this.gotoEditDesign(0,1,'#adadad','#f8f8f8')}>Select Design</button>
                  </div>
                </div>
                <div className="col-sm-4 classified_ad">
                  <div className="format_design">
                    <img src={singleB_W2} alt="" />
                    <button onClick={()=>this.gotoEditDesign(0,2,'#adadad','#f8f8f8')}>Select Design</button>
                  </div>
                </div>
                <div className="col-sm-4 classified_ad">
                  <div className="format_design">
                    <img src={singleB_W3} alt="" />
                    <button onClick={()=>this.gotoEditDesign(0,3,'#adadad','#efefef')}>Select Design</button>
                  </div>
                </div>
                </> 
                }
               
                <div className="col-sm-12 mt-5">
                 <div className="newspaperList highlight">
                    <h5>HAVE YOUR OWN DESIGN? CLICK TO UPLOAD </h5> 
                    
                    <div className="newsRight">
                      <span className="image_selected_span">{this.state.imageSelected}</span>
                      <div class="wrapper">
                        <div class="file-upload">
                          <input type="file" id="upload_design" accept="image/png, image/gif, image/jpeg" onChange={this.handleFileChange} />
                        </div>
                      </div>
                      {/* <button onClick={()=>this.gotoEditDesign(1,1)}>upload</button> */}
                    </div>

                  </div>
                </div>

                {this.state.upload_design ?
                <>
                <div className="col-sm-12 mt-3">
                 <div className="newspaperList">
                    <h5>Select Ad Size</h5> 
                    <div className="newsRight">
                      <div className="adsize_dropdowns">
                        <div className="adsize_w_h">
                          <div class="width_height_normal">
                            <label for="width">Width</label>
                            <select 
                                name="width"
                                onChange = {this.handledWidth}
                                required="required">
                                {this.state.whole_width.map((data,index)=>{
                                // console.log("dtata",data,index)
                                return( 
                                  <option value={data}>{data}cm</option>
                                )})}
                            </select>
                          </div>
                          <div class="width_height_normal">
                            <label for="height">Height</label>
                            <select 
                                name="height"
                                onChange = {this.handledHeight}
                                required="required">
                                <option value='5'>5cm </option>
                                <option value='6'>6cm </option>
                                <option value='7'>7cm </option>
                                <option value='8'>8cm </option>
                                <option value='9'>9cm </option>
                                <option value='10'>10cm </option>
                                <option value='11'>11cm </option>
                                <option value='12'>12cm </option>
                                <option value='13'>13cm </option>
                                <option value='14'>14cm </option>
                                <option value='15'>15cm </option>
                                <option value='16'>16cm </option>
                                <option value='17'>17cm </option>
                                <option value='18'>18cm </option>
                                <option value='19'>19cm </option>
                                <option value='20'>20cm </option>
                                <option value='21'>21cm </option>
                                <option value='22'>22cm </option>
                                <option value='23'>23cm </option>
                                <option value='24'>24cm </option>
                                <option value='25'>25cm </option>
                                <option value='26'>26cm </option>
                                <option value='27'>27cm </option>
                                <option value='28'>28cm </option>
                                <option value='29'>29cm </option>
                                <option value='30'>30cm </option>
                                <option value='31'>31cm </option>
                                <option value='32'>32cm </option>
                                <option value='33'>33cm </option>
                                <option value='34'>34cm </option>
                                <option value='35'>35cm </option>
                                <option value='36'>36cm </option>
                                <option value='37'>37cm </option>
                                <option value='38'>38cm </option>
                                <option value='39'>39cm </option>
                                <option value='40'>40cm </option>
                                <option value='41'>41cm </option>
                                <option value='42'>42cm </option>
                                <option value='43'>43cm </option>
                                <option value='44'>44cm </option>
                                <option value='45'>45cm </option>
                                <option value='46'>46cm </option>
                                <option value='47'>47cm </option>
                                <option value='48'>48cm </option>
                                <option value='49'>49cm </option>
                                <option value='50'>50cm </option>
                                <option value='51'>51cm </option>
                                <option value='52'>52cm </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-3">
                  <div className="newspaperList">
                    <h5>{this.state.newsPaperRecord}  Rs {this.state.perSizePrice}/sqcm X {this.state.displayWidth}cm (W) X {this.state.displayHeight}cm (H)</h5> 
                    <div className="newsRight">
                      <h4>Rs {this.state.totalAmount}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="date_payment_btns">
                   <button className="upload_design_btn" onClick={()=>this.gotoEditDesign(1,1)}>select dates & make payment</button>
                  </div>
                </div>
                </>
                :null}
            </div> 
            </form>
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main> 
  </DocumentMeta> 
  )}
}




