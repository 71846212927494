import React from "react";
import logo from '../../assets/img/logos/footer_logo.png'
import phone_icon from '../../assets/img/icons/phone.png'
import pin_icon from '../../assets/img/icons/pin.png'
import gmail_icon from '../../assets/img/icons/gmail.png'
import fb from '../../assets/img/icons/fb.png'
import insta from '../../assets/img/icons/insta.png'
import pinterest from '../../assets/img/icons/pinterest.png'
import payment from '../../assets/img/icons/payment.png'
import {Link} from "react-router-dom";
import { withRouter } from 'react-router';


class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.adNav = this.adNav.bind(this)
  }

  adNav(){
    // alert("hii")
     this.props.history.replace({ 
      pathname: '/step1',
      state: {  
        id:'',
        id1:'',
        id2:'',
        id3:''
      }})
    }

render() {

return (
    <footer>
    <section className="footer1">
      <div className="aboutSidebar">
        <Link to="/">
        <div className="footerlogo">
          <img src={logo} alt="" />
        </div></Link>
        <h2>About us</h2>
        <p>Timestribute.com is Obituary/tribute and remembrance advertisement portal which helps us relive the lives of our ancestors and departed loved ones. Here one can upload, store, publish & share biography, family tree, videos, photos, people can post condolence messages, and also receive death anniversary reminders. 
            Times Tribute will provide better platform and economical/chipper rates of all leading newspapers to publish death related and remembrance advertisement</p>
        <div className="social_ions">
          <a href="https://www.facebook.com/timestribute.timestribute" target="blank"><img src={fb} alt="" /></a>
          <a href="https://www.instagram.com/timestribute/" target="blank"><img src={insta} alt="" /></a>
          <a href="https://twitter.com/TimesTribute" target="blank"><img src={pinterest} alt="" /></a>
          {/* <a href="" target="blank"><img src={googleicon} alt="" /></a> */}
        </div>
      </div>
      <div className="footerRight">
        <div className="contact_info">
          <div className="contactdiv border_right">
            <a className="whatsp_link_footer" href="tel:0120-4216117"><img src={phone_icon} alt="" /></a>
            <div className="cont_details">
              <h2>Phone</h2>
              <a className="whatsp_link_footer" href="tel:0120-4216117"><p>0120-4216117</p></a>
            </div>
          </div>
          <div className="contactdiv border_right">
          <a className="whatsp_link_footer" href="mailto:timestribute@gmail.com"><img src={gmail_icon} alt="" /></a>
            <div className="cont_details">
              <h2>Email</h2>
              <a className="whatsp_link_footer" href="mailto:timestribute@gmail.com"><p>timestribute@gmail.com</p></a>
            </div>
          </div>
          <div className="contactdiv">
            <img src={pin_icon} alt="" />
            <div className="cont_details">
              <h2>Location</h2>
              <p>C-64, 1st floor Sector- 51, Noida 201301</p>
            </div>
          </div>
        </div>
        <div className="innerPagesLinks">
          <ul>
            <li><Link to="/obituary">Obituary</Link></li>
            <li><Link to="/remembrance">Remembrance</Link></li>
            <li><Link to="/real_heros">Tribute to real heroes</Link></li>
            <li><Link to="/celebrity">Celebrity Remembrance</Link></li>
            <li><a className="cursor-pointer"  onClick={this.adNav}>ad in news paper</a></li>
            <li><Link to="/contact">Contact</Link></li>
            {/* <li><a>press releases</a></li> */}
          </ul>
          <ul>
            {/* <li><a>mission & vision</a></li> */}
            <li><Link to="/about">about us</Link></li>
            {/* <li><a>Tribute to real heroes</Link></li> */}
            <li><Link to="/feedbacks">customer feedbacks</Link></li>
            <li><Link to="/join_us">join us</Link></li>
            <li><Link to="/faq">faq</Link></li>
            {/* <li><a>payment options</Link></li> */}
            <li><Link to="/terms_condition">terms & conditions</Link></li>
            <li><Link to="/privacy_policy">privacy & policy</Link></li>
          </ul>
          <div className="address_detail">
            <h3>contact us</h3>
            <p>timestribute@gmail.com</p>
            {/* <h2>SRI SAI Media</h2> */}
            <p>C-64, 1st floor Sector- 51,  <br/>Noida 201301</p>
            <p>Mobile No. +91-9810506092 </p>
          </div>
        </div>
      </div>
    </section>
    <section className="footer2">
      <div className="bottomFooter">
        <div class="footerparts border_right">
          <img src={payment} alt="" />
          <h5>Easy tracking</h5>
        </div>
        <div class="footerparts border_right">
          <img src={payment} alt="" />
          <h5>Easy cancelation & refund</h5>
        </div>
        <div class="footerparts">
          <img src={payment} alt="" />
          <h5>100% secure payment</h5>
        </div>
        <div class="footerparts">
         <p>@2021 timestribute.com. All rights reserved</p>
        </div>
      </div>
    </section>
  </footer>
)}
}

export default withRouter(Footer);