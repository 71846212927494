import React from "react";
import { FontAwesomeIcon } from '../../node_modules/@fortawesome/react-fontawesome';
import {faEnvelope, faMapMarker, faMapPin, faPhone, } from "@fortawesome/free-solid-svg-icons";
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from "axios"
import validator from 'validator'
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import DocumentMeta from 'react-document-meta';

export default class Feedbacks extends React.Component {
  constructor(){
    super()
    this.state={
      citylist:[],
      message:'',
      full_name:'',
      email:'',
      mobile:'',
      city:'',
      submitmsg:false,
      messageerror:'',
      moberror:'',
      emailerror:"",
      full_nameerror:'',
      cityerror:'',
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }
  componentDidMount(){
    axios.get(API_BASE_URL+`locationlist/`).then( res =>{ 
     // console.log(res.data)
      this.setState({citylist:res.data})
    })

    axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 9}}).then(res=>{
      // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })

    axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'feedback' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

  }
  validationform =()=> {
    let isvalid = true
   
    if(this.state.message==''){
      this.setState({messageerror:"Please fill your feedback/complaint"})
      isvalid=false
    }else if(this.state.message!=='' && this.state.message.length>3 ){
      this.setState({messageerror:''})
      
  
    }
    if(this.state.full_name==''){
      this.setState({full_nameerror:"Name field shouldn't be blank"})
      isvalid=false
    }else if(this.state.full_name!=='' && this.state.full_name.length>3 ){
      this.setState({full_nameerror:''})
      
  
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (validator.isEmail(this.state.email)) {
      this.setState({emailerror:''})
      
    }else if(!validator.isEmail(this.state.email)){
      
      this.setState({emailerror:"Invalid email"})
      isvalid=false
    }
    if(this.state.email ===""){
      this.setState({emailerror:"Email filled shouldn't be blank"})
      isvalid=false
    }
 
  if(!isNaN(this.state.mobile) ){
    if(this.state.mobile.length<10){
      this.setState({moberror:'please enter 10 digit mobile number'})
      isvalid=false
    }
    
    if( this.state.mobile.length===10 ){
      this.setState({moberror:''})
    }
  }else if (isNaN(this.state.mobile)){
    this.setState({moberror:'Mobile number is not valid'})
    isvalid=false
   }
   if(this.state.mobile ===""){
    this.setState({moberror:'Mobile number is not valid'})
    isvalid=false
  }
  if (this.state.mobile !== "undefined") {

    var pattern = new RegExp(/^[0-9\b]+$/);
  
    if (!pattern.test(this.state.mobile)) {
  
      isvalid = false;
      this.setState({moberror:'Please enter only number.'})
     // errors["phone"] = "Please enter only number.";
  
    }else if(this.state.mobile.length != 10){
  
      isvalid = false;
      this.setState({moberror:'Please enter valid phone number.'})
      //errors["phone"] = "Please enter valid phone number.";
  
    }
  }
  if(this.state.city==''){
    this.setState({cityerror:"Please select city"})
    isvalid=false
  }else if(this.state.city!==''  ){
    this.setState({cityerror:''})
    }
   return isvalid
  }

  handleChange=(e)=>{
   // console.log(e.target.name)
    let name = e.target.name
       if(name=="message"){
        this.setState({message:e.target.value})
       }
       if(name=="full_name"){
        this.setState({full_name:e.target.value})

      }
      if(name=="email"){
        this.setState({email:e.target.value.trim()})

      }
      if(name=="mobile"){
        this.setState({mobile:e.target.value.trim()})

      }
      if(name=="city"){
        
        this.setState({city:e.target.value})
      }
  }

  handleSubmit=(e)=>{
       e.preventDefault()
       if(this.validationform()){

       const formData = new FormData;
       formData.append('name',this.state.full_name)
       formData.append('requirments',this.state.message)
       formData.append('email',this.state.email)
       formData.append('mobile',this.state.mobile)
       formData.append('city',this.state.city)

       axios({
         method:'post',
         url:API_BASE_URL+'feedback/',
         data:formData,
       }).then(res=>{console.log(res)
      if(res.status==201){
        this.setState({submitmsg:true})
        e.target.reset()
       
      }
    })
  }
  }
  onKeyPress(event) {
    return (event.charCode >= 48 && event.charCode <= 57) 
  }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

  const citylist = this.state.citylist.map((val)=>{
   return <option   key = {val.id} value = {val.id}>{val.city_name}</option>
  })
 
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>

    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 m-auto">
          <div class="commonHead">
              <h2>Feedback/Complaint</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera join_us">
            <p><b>Lets us know your requirements</b></p>
          </div>
          </div>
        </div>
      </div>
    </section>

     <section className="contact_main">
        <div className="container">
          <div className="row">

            <div className="col-sm-6 m-auto">
            {this.state.submitmsg?<p className="successmsg succes_smg" >Thank you! We take customer satisfaction seriously and are glad to hear from you.Someone in our team will contact you soon</p>:null}

              <form  onSubmit={this.handleSubmit} className="contact-form join_form" autocomplete="off">
                <div className="row">
                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Describe Your Requirements</label>
                      <textarea onChange={this.handleChange} name="message" placeholder="Your Message Here" rows="5"></textarea>
                     <span className="validationAlert" >{this.state.messageerror}</span> 
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Your Name </label>
                      <input onChange={this.handleChange} type="text" name="full_name" placeholder="Your Name" /> 
                      <span className="validationAlert" >{this.state.full_nameerror}</span> 
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Your Email</label>
                      <input  onChange={this.handleChange} type="text" name="email" placeholder="Your Email"  /> 
                     <span className="validationAlert" >{this.state.emailerror}</span> 
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <label>Mobile</label>
                      <input onChange={this.handleChange}   maxLength={10} type="text" name="mobile" placeholder="Your Mobile No." />
                      <span className="validationAlert" >{this.state.moberror}</span> 
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div class="form-group feedback_select_city">
                    <label>City</label>
                      <select name ="city" onChange={this.handleChange} >
                        <option>- Select City -</option>
                      {citylist}
                      </select>
                      <span className="validationAlert" >{this.state.cityerror}</span>
                    </div>
                     
                  </div>
                  <div className="col-sm-12">
                    <div class="form-group set_reg_btn">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-sm-6">
              <div className="feedback_bside">

              </div>
            </div>
          </div>
        </div>
      </section>
      <EnquiryForm></EnquiryForm>
      <CommonLogoSlider></CommonLogoSlider>

    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}




