import React from "react";
import card2_img from '../assets/img/remembrance_side.png'
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from "axios";
import { API_BASE_URL , IMG_BASE_URL } from "../config/api";
import DocumentMeta from 'react-document-meta';

export default class Remembrance extends React.Component {

  constructor(props){
    super(props)
    this.state={
      BannerImg:'',
      BannerContent:'',
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }

  componentDidMount(){
   
    axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'remembrance' } }).then(res=>{
     // console.log("dataaaaa-dgfffg-----",res.data)
      this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
      this.setState({BannerContent:res.data[0].description})
    })

    axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 3 }}).then(res=>{
       // console.log("dataaaaa------",res.data[0])
       this.setState({metaKeyword: res.data[0].meta_keyword,
       metaTitle:res.data[0].title,
       metaDescription:res.data[0].meta_description})
     })

  }
 
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };
 
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>

    <section className="aboutSection commonPg">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 m-auto">
          <div class="commonHead">
              <h2>Remembrance Advertisements</h2>
              <div class="dash"></div>
          </div>
          <div className="about_pera">
            <p>Remembrance advertisement is like if you do something in remembrance of a dead person or your relatives, you do it as a way of showing that you want to remember them and that you respect them and always missing you. Remembrance ad published in Run on line (ROL) form also and Newspapers rates are very economical. The Run on line (ROL) advertisement will publish in newspapers with the photograph. Sample design is in this page. we have more options like Classified display advertisement (width is retracted in 3 cm and height is minimum 5 cm and maximum is your choice till 52 cms) and we do display advertisement no restriction in width (minimum 4 cm x 4 cm and maximum any thinks as you like or as per your need.</p>
            <p>The Silent joy or remembering a loved one. The moments you shared together. Little moments that make life beautiful. Share them with Friends relatives, Neighbors, Colleagues with all those whose lives the departed Soul has touched. Because while life goes on, when you share memories, they stay on forever.</p>
          </div>
          </div>
          <div className="col-sm-4 obituary_side_img remembrance">
          </div>
        </div>
      </div>
    </section>

    <section className="requirement_section">
        <div className="container bg_blur">
            <div className="row">
                <div className="col-sm-5 m-auto text-center img_custom_height">
                    <img src={card2_img} alt="" />
                    <h3>Remembrance advertisement example</h3>
                </div>
                <div className="col-sm-7 m-auto">
                    <div className="commonHeadLight">
                        <h2>Requirements</h2>
                        <div className="dash"></div>
                    </div>
                    <div className="requirement_detail">
                        <h4>Death Certificate</h4>
                        <h4>a photograph</h4>
                        {/* <h4>a photograph of the deceased</h4> */}
                        <h4>a photograph of the deceased</h4>
                        <h4>size of advertisement that you wish to release</h4>
                        <h4>date of release of the advertisement</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}

