import React from "react";
import whatsaap from '../assets/img/whatsaap.png'
import facebook from '../assets/img/facebook.png'
import twitter from '../assets/img/twitter.png'
import vission from '../assets/img/vission.png'
import CommonHeader from './Common/commonHeader'
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import CommonLogoSlider from "./Common/commonLogoSlider";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from "axios";
import { API_BASE_URL , IMG_BASE_URL } from "../config/api";
import DocumentMeta from 'react-document-meta';

export default class About extends React.Component {
  constructor(props){
    super(props)
    this.state={
      contentData:[],
      BannerImg:'',
      BannerContent:'',
      teamData:[],
      metaKeyword: '',
      metaDescription: '',
      metaTitle: ''
    }
  }

  componentDidMount(){
  
    axios.get(API_BASE_URL+`contents/`,{ params: { cid: 1 } }).then(res=>{
    //  console.log("dataaaaa",res.data)
      this.setState({contentData:res.data})
    })
  
    axios.get(API_BASE_URL+`teams/`).then(res=>{
     // console.log("dataaaaa------",res.data)
      this.setState({teamData:res.data})
    })
    
    axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 1 }}).then(res=>{
     // console.log("dataaaaa------",res.data[0])
      this.setState({metaKeyword: res.data[0].meta_keyword,
      metaTitle:res.data[0].title,
      metaDescription:res.data[0].meta_description})
    })

    axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'about' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
      this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
      this.setState({BannerContent:res.data[0].description})
    })

  }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

    const contentData=this.state.contentData.map((val,index)=>{
    return(
    <div  key={index} dangerouslySetInnerHTML={ {__html: val.content} } />
    )
    })

    const teamDataConst = this.state.teamData.map((teamData)=>{
      return(
        <div className="col-sm-6 col-md-6 col-lg-4">
          <div className="team_1">
            <img className="m-auto member" src={IMG_BASE_URL+teamData.image} alt="" />
            <div className="about_member">
               <a href={teamData.whatsapp_link}><img src={whatsaap} alt="" /></a>
               <a href={teamData.fb_link}><img src={facebook} alt="" /></a>
               <a href={teamData.twitter_link}><img src={twitter} alt="" /></a>
              <div className="social_media">
                <h4 className="mrb0">{teamData.name}</h4>
                <p className="mrb0">{teamData.designation}</p>
              </div>
            </div>
          </div>
        </div>
      )
    })

return (
<DocumentMeta {...meta}>
<main className="mainBg">
   <CommonHeader></CommonHeader>
   <EnquiryDetails></EnquiryDetails>
   <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <div className="inner_heading">
            {/* <p>{this.state.BannerContent}</p> */}
          </div>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  </section>

   <section className="aboutSection commonPg">
      <div className="container">
      {/* {contentData} */}

         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
              <div class="about_banner">
                <img src="http://173.212.250.62:3016/media/images/banners/about_times_tiny.png" alt="" />
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 m-auto">
               <div class="commonHead">
                  <h2>About Us</h2>
                  <div class="dash"></div>
               </div>
               <div class="about_pera1">
                  <p>Timestribute.com is Noida (India) based company powered by SRI SAI MEDIA established since 2006 We are always keen to provide the best service to our clients. Through this website we are aiming at providing easier way book tribute ads. Timestribute. com gives best rates to book obituary ad all over India in any leading newspaper(s). We assure you of our give best services at all time. We also give best design support which is FREE OF COST (multiple designs till satisfaction). We are trying to give you the service to book your ad at the comfort of your home.</p>
               </div>
            </div>
         </div>

      </div>
   </section>

   <section className="mission_section commonPg">
      <div className="container">
        <div className="our_mission">
            <div className="row">
              <div className="col-sm-12 col-md-8 mr-auto">
                  <div className="about_mission">
                    <div class="commonHead">
                      <h2>Our Mission</h2>
                      <div class="dash"></div>
                    </div>
                    <div className="about_pera1">
                      <p>We are establishing this portal, timestribute.com so as to make it easy for our customers to book tribute/obituary/remembrance ads (ROL/Classified display/ Normal display online from anywhere and at any time. In addition we are providing FREE OF COST designing of the advertisement. Our team remains in constant contact with the leading publications helping our customers to get quality services at optimum price. This is a user friendly site, but if the user wants they can also contact us through telephone or email or whatsAap. In coming years, we are planning to add on more features to this site for different categories such as tribute to real heroes, remembrance, death anniversaries etc. it would surely enhance the quality of our services for the users.</p>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
   </section>

   <section className="aboutSection commonPg">
      <div className="container">
         <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="about_banner">
                <img src={vission} alt="" />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 m-auto">
               <div class="commonHead">
                  <h2>Our Vision</h2>
                  <div class="dash"></div>
               </div>
               <div className="about_pera1">
                  <p>Since its inception, Times Tribute envisions establishing a hallmark for itself in a diversified market base for its clients. For achieving this vision, the company intends to leverage its operational skills and optimal usage.   Times Tribute aims to serve our customers better not only in terms of the reach of their advertisement but also in creating user-friendly software to help them choose the platform and format of their choice wherever and whenever they want. Our website has numerous options for newspaper publications. One can choose among them and select the best one suited for the desired locality and public reach they are looking for at an optimum rate.
It's just the beginning, and there's a lot to follow to successfully attain our goal of creating the platform for easy advertisement with maximal reach. Often, it is a few years down the line where you plateau and have difficulty progressing towards your vision, but this again instils the importance of the experience of your business.
We strive to work hard to avoid this plateau set in our path. With almost two decades of experience in this field, we have an enormous engagement and reach and thus impeccably are the best suited to solve all your advertisement needs. Our vision is to create local opportunity, growth, best quality, integrity, maximal reach and better customer service.
</p>
               </div>
            </div>
         </div>
      </div>
   </section>

   <section className="our_team commonPg mr-bottom-50">
    <div className="container">
      <div class="commonHead">
        <h2>Our Team</h2>
        <div class="dash"></div>
      </div>
      <div className="about_pera">
        <p>It’s important that consumers and potential clients know who is “behind the mask” because people like dealing with people, not companies and brands. So meet our team members we will happy to help you. </p>
      </div>
      <div className="row">
        {teamDataConst}
        {/* <div className="col-sm-4">
          <div className="team_1">
            <img className="m-auto member" src={team1} alt="" />
            <div className="about_member">
              <img src={whatsaap} alt="" />
              <img src={facebook} alt="" />
              <img src={twitter} alt="" />
              <div className="social_media">
                <h4 className="mrb0">Ahmed Hassan</h4>
                <p className="mrb0">Senior Reporter</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="team_1">
            <img className="m-auto member" src={team2} alt="" />
            <div className="about_member">
              <img src={whatsaap} alt="" />
              <img src={facebook} alt="" />
              <img src={twitter} alt="" />
              <div className="social_media">
                <h4 className="mrb0">Priya Yadav</h4>
                <p className="mrb0">Junior Reporter</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="team_1">
            <img className="m-auto member" src={team3} alt="" />
            <div className="about_member">
              <img src={whatsaap} alt="" />
              <img src={facebook} alt="" />
              <img src={twitter} alt="" />
              <div className="social_media">
                <h4 className="mrb0">Sachin Yadav</h4>
                <p className="mrb0">Audio Editor</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
   </section>
   <EnquiryForm></EnquiryForm>
   <CommonLogoSlider></CommonLogoSlider>
   <Footer></Footer>
</main>
</DocumentMeta>
)}
}

