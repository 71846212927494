import React from "react";
import { FontAwesomeIcon } from '../../node_modules/@fortawesome/react-fontawesome';
import {faEnvelope, faMapMarker, faMapPin, faPhone, } from "@fortawesome/free-solid-svg-icons";
import CommonHeader from './Common/commonHeader'
import CommonLogoSlider from "./Common/commonLogoSlider";
// import CommonBanner from "./Common/commonBanner";
import EnquiryForm from "./Common/enquiryform";
import Footer from './Common/footer'
import EnquiryDetails from './Common/enquerydetail'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../config/api';
import validator from 'validator'
import DocumentMeta from 'react-document-meta';

export default class Contact extends React.Component {
//   Praveen, 11:18
// Contact US (POST) api :
// http://173.212.250.62:3016/api/contactus/
// parameters = name , email , mobile , messege
constructor(props) {  
  super(props); 
this.state = {
  BannerImg:'',
  BannerContent:'',
  fname:'',
  lname:'',
  message:'',
  mobile:'',
  email:'',
  fnameerror:false,
  lnameerror:false,
  emailerror:false,
  moberror:false,
  messageerror:false,
  submitMsg:false,
  metaKeyword: '',
  metaDescription: '',
  metaTitle: ''
}}

componentDidMount(){

  axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'contact' } }).then(res=>{
    // console.log("dataaaaa-dgfffg-----",res.data)
     this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
     this.setState({BannerContent:res.data[0].description})
   })

   axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 2 }}).then(res=>{
    // console.log("dataaaaa------",res.data[0])
    this.setState({metaKeyword: res.data[0].meta_keyword,
    metaTitle:res.data[0].title,
    metaDescription:res.data[0].meta_description})
  })

}

validationform =()=> {
  let isvalid = true
  if(this.state.fname==''){
    this.setState({fnameerror:true})
    isvalid=false
  }else if(this.state.fname!==''&& this.state.fname.length>3){
    this.setState({fnameerror:false})
  }
  if(this.state.lname==''){
    this.setState({lnameerror:true})
    isvalid=false
  }else if(this.state.lname!=='' && this.state.lname.length>3){
    this.setState({lnameerror:false})
  }
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  if (validator.isEmail(this.state.email)) {
    this.setState({emailerror:false})
  }else{ 
    this.setState({emailerror:true})
    isvalid=false
  }
  if(!isNaN(this.state.mobile) ){
    if(this.state.mobile.length<9){
      this.setState({moberror:'please enter 10 digit mobile number'})
    }
    if( this.state.mobile.length===10 ){
      this.setState({moberror:''})
    }
  }else if (isNaN(this.state.mobile)){
    this.setState({moberror:'Mobile number is not valid'})
    isvalid=false
   }
   if(this.state.mobile ===""){
    this.setState({moberror:'Please fill you mobile number'})
    isvalid=false
  }

  if (this.state.mobile !== "undefined") {

    var pattern = new RegExp(/^[0-9\b]+$/);
  
    if (!pattern.test(this.state.mobile)) {
  
      isvalid = false;
      this.setState({moberror:'Please enter only number.'})
     // errors["phone"] = "Please enter only number.";
  
    }else if(this.state.mobile.length != 10){
  
      isvalid = false;
      this.setState({moberror:'Please enter valid phone number.'})
      //errors["phone"] = "Please enter valid phone number.";
  
    }
  }
 
  if(this.state.location==''){
    this.setState({locationerror:true})
    isvalid=false
  } else if(this.state.location!==''){
    this.setState({locationerror:false})
    
  }
 
  if(this.state.message==''){
    this.setState({messageerror:true})
    isvalid=false
  } else if(this.state.message!==''){

    this.setState({messageerror:false})
    
  }
  return isvalid

 
}

handleChange=(e)=>{
 
  let name = e.target.name
  if(name === "first_name"){
    //console.log("ff",e.target.value)
    this.setState({fname:e.target.value})
  }
  if(name === "last_name"){
    //console.log("ll",e.target.value)
    this.setState({lname:e.target.value})
  }
  if(name === "email"){
    //console.log("email",e.target.value)
    this.setState({email:e.target.value})
  }
  if(name === "mobile"){
    this.setState({mobile:e.target.value})
  }
  if(name === "message"){
    this.setState({message:e.target.value})
  }
  
}
handleSubmit=(e)=>{
  e.preventDefault();
 // console.log(">>>>>>>>>>>  ",isNaN(this.state.mobile))
  
  const lf = (this.state.fname.concat(" ",this.state.lname)).trim()
  //console.log("............",lf)
  // alert("hii")
  if(this.validationform()){
  const formData = new FormData();
  formData.append('name',lf)
  formData.append('email',this.state.email)
  formData.append('mobile',this.state.mobile)
  formData.append('messege',this.state.message)
 
    axios({
      method: 'post',
      url:API_BASE_URL+"contactus/",
     
      data: formData
      
    }).then(response => {
    //console.log(response)
    if(response.status===200){
      this.setState({submitMsg:true})
      e.target.reset();
    }
  })
  .catch((error) => {
  });
  }
}
render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };
 
  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <EnquiryDetails></EnquiryDetails> 
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>

     <section className="contact_main commonPg">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 m-auto">
              <div className="contact_box">

                <div className="contact_box_head">
                  <span>Contact</span>
                </div>

                <div className="contact_data">
                  <i><FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon></i>
                  <div className="address_field">
                    <h5>Location</h5>
                    <p>C-64, 1st floor Sector- 51,  <br/>Noida 201301 </p>
                  </div>
                </div>
                
                <div className="contact_data">
                <a className="whatsp_link_footer" href="tel:0120-4216117"><i><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></i></a>
                  <div className="address_field">
                    <h5>Phone</h5>
                    <a className="whatsp_link_footer" href="tel:0120-4216117"><p>0120-4216117</p></a>
                    <a className="whatsp_link_footer" href="tel:9810506092"><p>+91-9810506092</p></a>
                  
                  </div>
                </div>

                <div className="contact_data">
                <a className="whatsp_link_footer" href="mailto:timestribute@gmail.com"><i><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></i></a>
                  <div className="address_field">
                    <h5>Email</h5>
                    <a className="whatsp_link_footer" href="mailto:timestribute@gmail.com"><p>timestribute@gmail.com</p></a>
                    {/* <p>example@gmail.com</p> */}
                  </div>
                </div>

              </div>
            </div>

            <div className="col-sm-8 m-auto">
            {this.state.submitMsg?<p className="successmsg succes_smg" >We've received your message, someone in our team will contact you soon...</p>:null}
              <form onSubmit={this.handleSubmit} className="contact-form" autocomplete="off">
                <div className="row">
                  <div className="col-sm-6">
                    <div class="form-group custom_fields">
                      <input type="text" onChange={this.handleChange} name="first_name" placeholder="First Name" />
                      {this.state.fnameerror ?<p className="validationAlert" >Please enter your first name</p>:null}
 
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div class="form-group custom_fields">
                      <input type="text"  onChange={this.handleChange} name="last_name" placeholder="Last Name" /> 
                      {this.state.lnameerror ?<p className="validationAlert" >Please enter your last name</p>:null}

                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div class="form-group custom_fields">
                      <input type="text" onChange={this.handleChange} name="email" placeholder="Email" /> 
                      {this.state.emailerror?<p className="validationAlert" >Please enter valid email</p>:null}

                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div class="form-group custom_fields">
                      <input type="text"   maxLength={10} onChange={this.handleChange} name="mobile" placeholder="Mobile" /> 
                      {this.state.moberror?<p className="validationAlert" >Please enter valid number</p>:null}

                    </div>
                  </div>
                  
                  <div className="col-sm-12">
                    <div class="form-group custom_fields">
                      <textarea name="message" onChange={this.handleChange}  placeholder="Your Message Here" rows="5" ></textarea>
                      {this.state.messageerror?<p className="validationAlert" >Please enter some information </p>:null}

                    </div>

                    <div class="form-group custom_fields">
                        <button type="submit">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-sm-12 address_map">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14010.406599853757!2d77.3837577!3d28.6117249!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbaea53b76b6aa181!2sTimes%20Tribute!5e0!3m2!1sen!2sin!4v1626183539225!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
             */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.9689764440054!2d77.36260885017299!3d28.57069429354266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef2aaa33267d%3A0xbaea53b76b6aa181!2sTimes%20Tribute!5e0!3m2!1sen!2sin!4v1627633887480!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </section>

    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta>
  )}
}




