import $ from "jquery";

$(function() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $(".Header-top").addClass("activeScrollHeader");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $(".Header-top").removeClass("activeScrollHeader");
        }
    });
});



