import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_2.png";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from '../Common/tools'
import dummyImg from '../../assets/img/dummy.png';
import upload_pdf from  '../../assets/img/icons/upload_img.png';
import validator from 'validator'

var editor
var editor2

export default class NormaldesignAd extends React.Component {

  constructor(props){  
    super(props);
    this.state = {
      offerId: this.props.location.state.offerIdParam,
      publicationId: this.props.location.state.publicationIdParam,
      cityId: this.props.location.state.cityIdParam,
      adtypeId: this.props.location.state.adtypeIdParam,
      ContactName: '',
      ContactMobile: '',
      ContactEmail: '',
      activeWidth : '',
      displayWidth : 4,
      newsPaperRecord: '',
      pricePera : '',
      totalPrice : '',
      upload_design: '',
      upload_design_pdf: null,
      displayHeight: 4,
      BannerImg:'',
      BannerContent:'',
      bookingId:''
    }
    this.handleFileChange2 = this.handleFileChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handledWidth = this.handledWidth.bind(this);
    this.handledHeight = this.handledHeight.bind(this);
  }

  handleChangeName = (evt) =>{
    this.setState({ ContactName: evt.target.value });
  }
  handleChangeMobile= (evt) =>{
    this.setState({ ContactMobile: evt.target.value });
  }
  handleChangeEmail= (evt) =>{
    this.setState({ ContactEmail: evt.target.value });
  }

  validationform =()=> {
    let isvalid = true
  
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (validator.isEmail(this.state.ContactEmail)) {
      this.setState({emailerror:false})
    }else{ 
      this.setState({emailerror:true})
      isvalid=false
    }

    if(!isNaN(this.state.ContactMobile) ){
      if(this.state.ContactMobile.length<9){
        this.setState({moberror:'please enter 10 digit mobile number'})
      }
      if( this.state.ContactMobile.length===10 ){
        this.setState({moberror:''})
      }
    }else if (isNaN(this.state.ContactMobile)){
      this.setState({moberror:'Mobile number is not valid'})
      isvalid=false
     }
     if(this.state.ContactMobile ===""){
      this.setState({moberror:'Please fill you mobile number'})
      isvalid=false
    }

    if (this.state.ContactMobile !== "undefined") {

      var pattern = new RegExp(/^[0-9\b]+$/);
    
      if (!pattern.test(this.state.ContactMobile)) {
    
        isvalid = false;
        this.setState({moberror:'Please enter only number.'})
       // errors["phone"] = "Please enter only number.";
    
      }else if(this.state.ContactMobile.length != 10){
    
        isvalid = false;
        this.setState({moberror:'Please enter valid phone number.'})
        //errors["phone"] = "Please enter valid phone number.";
    
      }
    }
  
    return isvalid 
  }

  componentDidMount(){

    this.setState({content1:editor.props.data.blocks[0].data.text})
    this.setState({content2:editor2.props.data.blocks[0].data.text})

    axios({
      method: 'get',
      url: API_BASE_URL + 'basicrate/?offerid='+this.state.offerId
      }).then(response => { 
         // console.log("basic rates",response.data) 
          this.setState({newsPaperRecord : response.data.rate[0].publication.paper_name})
          this.setState({pricePera: response.data.rate[0].normal_price})
         // const total = parseFloat(this.state.displayHeight * this.state.displayWidth * this.state.pricePera).toFixed(2)  
         // this.setState({totalPrice:total}) 
          let totalprice = this.state.displayWidth * this.state.displayHeight * this.state.pricePera
         const total = parseFloat(totalprice).toFixed(2)
         this.setState({totalPrice : total}) 

      }).catch((error) => {
      });

     axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'step2' } }).then(res=>{
      // console.log("dataaaaa-dgfffg-----",res.data)
       this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
       this.setState({BannerContent:res.data[0].description})
     })

  let growWidth;
  if(this.state.displayWidth == 4){
    growWidth = 165
  }
  else if(this.state.displayWidth == 5){
    growWidth = 166
  }
  else if(this.state.displayWidth == 6){
    growWidth = 167
  }
  else if(this.state.displayWidth == 7){
    growWidth = 168
  }
  else if(this.state.displayWidth <= 15 && this.state.displayWidth >= 8){
    growWidth = this.state.displayWidth*22
  }else if(this.state.displayWidth == 16){
    growWidth = 334
  }else if(this.state.displayWidth == 17){
    growWidth = 336
  }else if(this.state.displayWidth == 18){
    growWidth = 338
  }else if(this.state.displayWidth == 19){
    growWidth = 340
  }else if(this.state.displayWidth == 20){
    growWidth = 342
  }else if(this.state.displayWidth == 21){
    growWidth = 344
  }else if(this.state.displayWidth == 22){
    growWidth = 346
  }else if(this.state.displayWidth == 23){
    growWidth = 348
  }else if(this.state.displayWidth == 24){
    growWidth = 350
  }else if(this.state.displayWidth == 25){
    growWidth = 352
  }else if(this.state.displayWidth == 26){
    growWidth = 354
  }else if(this.state.displayWidth == 27){
    growWidth = 356
  }else if(this.state.displayWidth == 28){
    growWidth = 358
  }else if(this.state.displayWidth == 29){
    growWidth = 360
  }else if(this.state.displayWidth == 30){
    growWidth = 362
  }else if(this.state.displayWidth == 31){
    growWidth = 364
  }else if(this.state.displayWidth == 32){
    growWidth = 366
  }
  this.setState({activeWidth: growWidth+'px'})

 }

 handledWidth(e){
  let width = e.target.value
  this.setState({displayWidth : width})
  //const total = parseFloat(this.state.displayHeight * width * this.state.pricePera).toFixed(2)  
  // console.log("totalp",total)
  //this.setState({totalPrice : total}) 
  let totalprice = width * this.state.displayHeight * this.state.pricePera
  const total = parseFloat(totalprice).toFixed(2)
  this.setState({totalPrice : total}) 

  let growWidth;
  // if(width == 4){
  //   growWidth = 90
  // }
  // else if(width == 5){
  //   growWidth = 91.5
  // }
  // else if(width == 6){
  //   growWidth = 93.5
  // }
  // else if(width == 7){
  //   growWidth = 95
  // }
  // else if(width <= 32 && width >= 8){
  //   growWidth = width*12
  // }
  if(width == 4){
    growWidth = 165
  }
  else if(width == 5){
    growWidth = 166
  }
  else if(width == 6){
    growWidth = 167
  }
  else if(width == 7){
    growWidth = 168
  }
  else if(width <= 15 && width >= 8){
    growWidth = width*22
  }else if(width == 16){
    growWidth = 334
  }else if(width == 17){
    growWidth = 336
  }else if(width == 18){
    growWidth = 338
  }else if(width == 19){
    growWidth = 340
  }else if(width == 20){
    growWidth = 342
  }else if(width == 21){
    growWidth = 344
  }else if(width == 22){
    growWidth = 346
  }else if(width == 23){
    growWidth = 348
  }else if(width == 24){
    growWidth = 350
  }else if(width == 25){
    growWidth = 352
  }else if(width == 26){
    growWidth = 354
  }else if(width == 27){
    growWidth = 356
  }else if(width == 28){
    growWidth = 358
  }else if(width == 29){
    growWidth = 360
  }else if(width == 30){
    growWidth = 362
  }else if(width == 31){
    growWidth = 364
  }else if(width == 32){
    growWidth = 366
  }
  this.setState({activeWidth: growWidth+'px'})
 }
 handledHeight(e){
  let height = e.target.value
  this.setState({displayHeight : height})

  let totalprice = this.state.displayWidth * height * this.state.pricePera
  const total = parseFloat(totalprice).toFixed(2)
  this.setState({totalPrice : total}) 

 // const total = parseFloat(height * this.state.displayWidth * this.state.pricePera).toFixed(2)  
  // console.log("totalp",total)
 // this.setState({totalPrice : total}) 
 // this.setState({activeWidth: 'normal_width'})
 }

  handleFileChange2(evt) {
    this.setState({upload_design_pdf: evt.target.files[0]})
  // console.log("hello",evt.target.files[0].name)
    this.setState({pdfSelected: evt.target.files[0].name})
  }

  handleFileChange =(evt) =>{
    evt.preventDefault();

    const formData = new FormData();
    formData.append("design_img",evt.target.files[0]);
    formData.append("offer" , this.state.offerId);
    formData.append("publication", this.state.publicationId);
    formData.append("city", this.state.cityId);
    formData.append("adtype", this.state.adtypeId);
    formData.append("types_of_ad", '1');
    formData.append("contact_name", this.state.ContactName);
    formData.append("contact_email", this.state.ContactEmail);
    formData.append("contact_mobile", this.state.ContactMobile);

    axios({
      method: 'post',
      url: API_BASE_URL+'booking/',
      data: formData 
      }).then(response => {
       //console.log("post",IMG_BASE_URL+response.data.data1.design_img)
       this.setState({upload_design : IMG_BASE_URL+response.data.data1.design_img}) 
       this.setState({bookingId : response.data.data1.id}) 
       
       //console.log(this.state.upload_design)
      })
      .catch((error) => {
      //console.log(error)
      });

  }

  oneditorChange=(e)=>{
    e.saver.save().then((outputData) => {
     // console.log('Article data: ', outputData.blocks[0].data.text)
      this.setState({content1: outputData.blocks[0].data.text})
    }).catch((error) => {
     // console.log('Saving failed: ', error)
    });
  }

  oneditorChange2=(e)=>{
    e.saver.save().then((outputData) => {
     // console.log('Article data: ', outputData.blocks[0].data.text)
      this.setState({content2: outputData.blocks[0].data.text})
    }).catch((error) => {
     // console.log('Saving failed: ', error)
    });
  }

handleSubmit = (event)=>{
 // alert("hiii")
  event.preventDefault();

  if(this.state.bookingId){

    const formData1 = new FormData();
    formData1.append("bid", this.state.bookingId);
    formData1.append("total_amt", this.state.totalPrice);
    formData1.append("size", this.state.displayWidth);
    formData1.append("height_size", this.state.displayHeight);
    formData1.append("content_1", this.state.content1);
    formData1.append("content_2", this.state.content2);
    formData1.append("color_mode", '1');
    formData1.append("frame_type", 'normal');
    if(this.state.upload_design_pdf){
      formData1.append("pdf_file",this.state.upload_design_pdf);
    }
    
  axios({
    method: 'patch',
    url: API_BASE_URL+'booking/',
    data: formData1
      }).then(res => {
          // console.log("normal display booking patch",res.data)
          if(res.data.msg == 'Booking Done Successfully'){
            this.props.history.push({ 
              pathname: '/step3',
              state: {bookingId: this.state.bookingId}})
          }
          else{
            alert('something went wrong');
          }
        })
        .catch((error) => {
        // console.log(error)
        });
  
  }else{
    if(this.validationform()){
      const formData = new FormData();
      formData.append("offer" , this.state.offerId);
      formData.append("publication", this.state.publicationId);
      formData.append("city", this.state.cityId);
      formData.append("adtype", this.state.adtypeId);
      formData.append("types_of_ad", '4');
      formData.append("contact_name", this.state.ContactName);
      formData.append("contact_email", this.state.ContactEmail);
      formData.append("contact_mobile", this.state.ContactMobile);
      formData.append("total_amt", this.state.totalPrice);
      formData.append("size", this.state.displayWidth);
      formData.append("height_size", this.state.displayHeight);
      formData.append("content_1", this.state.content1);
      formData.append("content_2", this.state.content2);
      formData.append("color_mode", '1');
      formData.append("frame_type", 'normal');
      if(this.state.upload_design_pdf){
        formData.append("pdf_file",this.state.upload_design_pdf);
      }
      
    axios({
      method: 'post',
      url: API_BASE_URL+'booking/',
      data: formData
        }).then(res => {
            // console.log("normal display booking post",res.data)
            if( res.data.msg == 'Booking Done Successfully'){
              this.props.history.push({ 
                pathname: '/step3',
                state: {bookingId: res.data.data1.id}})
            }
            else{
              alert('something went wrong');
            }
          })
          .catch((error) => {
          // console.log(error)
          });
        }
  }

}

render() {

  const html = `
  <div class='block_ui'>
    <h2>Prayer Meeting</h2>
    <p class="ita_p">you were our shining & guiding light.</p>
  </div>`;

  const html2 = `
  <div class='block_ui'>
    <h4 class="h4_classi">Prithvi</h4>
    <h5 class="h5_classi">it is with deepest sorrow that we inform our beloved.</h5>
    <hr class="edit">
    <p class="p_classi">Passed away peacefully on sunday,march 16th you were full of compation a formidable intellect, and inspired everyone in your orbit to attain a deeper level of humainty.</p>
    <hr class="edit">
    <p class="p_classi">Prayer meeting will be held in the.....sector.....at 4:00PM wednesday,march 19th.</p>
    <h5 class="h5_classi">In Grief</h5>
    <p class="p_classi">Sujay,Shyam Singh, Karan & all Family.</p>
    </div>`;

  // <hr class="edit">
  //   <p class="p_classi">A universal mother remembered for her warm generosity and a life well lived in service to humanity.</p>
  //   <hr class="edit">
  //   <span>family member</span>
  //   <span>family member</span>
  //   <span>family members & friends</span><br>
  //   <span>Email: example@gmail.com</span>
  

  return (
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
        <form autocomplete="off" onSubmit={this.handleSubmit}> 
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2 className="active">Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                <div className="col-sm-12">
                  <div className="contact-form">
                    <div className="row">
                        <div className="col-sm-12">
                          <div className="commonHead">
                            <h2>Provide Your Contact Details</h2>
                            <div className="dash"></div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="name">Name</label><br/>
                                <input type="text" name="name" value={this.state.ContactName}  onChange={this.handleChangeName} placeholder="Name" required/> 
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="mobile">Mobile</label><br/>
                                <input type="text" maxlength="10" minlength="10"  name="Mobile" value={this.state.ContactMobile} onChange={this.handleChangeMobile} placeholder="Mobile" required/> 
                                {this.state.moberror?<p className="validationAlert" >Please enter valid number</p>:null}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div class="form-group custom_fields">
                                <label for="email">Email</label><br/>
                                <input type="text" name="email" value={this.state.ContactEmail} onChange={this.handleChangeEmail} placeholder="Email" required/> 
                                {this.state.emailerror?<p className="validationAlert" >Please enter valid email</p>:null}
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-4">
                  <div className="commonHead">
                    <h2>Design your ad</h2>
                    <div className="dash"></div>
                  </div>
                  <div className="select_ad_text">
                    <h3>Select Ad Size</h3> 
                  </div>
                  <div className="color_mode comb_normal">
                    <div className="comb_text_align">
                      <div className="adsize_w_h">
                        <div class="width_height_normal">
                          <label for="width">Ad Width</label><br/>
                          <select 
                              name="width"
                              onChange = {this.handledWidth}
                              required="required">
                              <option value='4'>4cm </option>
                              <option value='5'>5cm </option>
                              <option value='6'>6cm </option>
                              <option value='7'>7cm </option>
                              <option value='8'>8cm </option>
                              <option value='9'>9cm </option>
                              <option value='10'>10cm </option>
                              <option value='11'>11cm </option>
                              <option value='12'>12cm </option>
                              <option value='13'>13cm </option>
                              <option value='14'>14cm </option>
                              <option value='15'>15cm </option>
                              <option value='16'>16cm </option>
                              <option value='17'>17cm </option>
                              <option value='18'>18cm </option>
                              <option value='19'>19cm </option>
                              <option value='20'>20cm </option>
                              <option value='21'>21cm </option>
                              <option value='22'>22cm </option>
                              <option value='23'>23cm </option>
                              <option value='24'>24cm </option>
                              <option value='25'>25cm </option>
                              <option value='26'>26cm </option>
                              <option value='27'>27cm </option>
                              <option value='28'>28cm </option>
                              <option value='29'>29cm </option>
                              <option value='30'>30cm </option>
                              <option value='31'>31cm </option>
                              <option value='32'>32cm </option>
                          </select>
                        </div>
                        <div class="width_height_normal">
                          <label for="height">Ad Height</label><br/>
                          <select 
                              name="height"
                              onChange = {this.handledHeight}
                              required="required">
                              <option value='4'>4cm </option>
                              <option value='5'>5cm </option>
                              <option value='6'>6cm </option>
                              <option value='7'>7cm </option>
                              <option value='8'>8cm </option>
                              <option value='9'>9cm </option>
                              <option value='10'>10cm </option>
                              <option value='11'>11cm </option>
                              <option value='12'>12cm </option>
                              <option value='13'>13cm </option>
                              <option value='14'>14cm </option>
                              <option value='15'>15cm </option>
                              <option value='16'>16cm </option>
                              <option value='17'>17cm </option>
                              <option value='18'>18cm </option>
                              <option value='19'>19cm </option>
                              <option value='20'>20cm </option>
                              <option value='21'>21cm </option>
                              <option value='22'>22cm </option>
                              <option value='23'>23cm </option>
                              <option value='24'>24cm </option>
                              <option value='25'>25cm </option>
                              <option value='26'>26cm </option>
                              <option value='27'>27cm </option>
                              <option value='28'>28cm </option>
                              <option value='29'>29cm </option>
                              <option value='30'>30cm </option>
                              <option value='31'>31cm </option>
                              <option value='32'>32cm </option>
                              <option value='33'>33cm </option>
                              <option value='34'>34cm </option>
                              <option value='35'>35cm </option>
                              <option value='36'>36cm </option>
                              <option value='37'>37cm </option>
                              <option value='38'>38cm </option>
                              <option value='39'>39cm </option>
                              <option value='40'>40cm </option>
                              <option value='41'>41cm </option>
                              <option value='42'>42cm </option>
                              <option value='43'>43cm </option>
                              <option value='44'>44cm </option>
                              <option value='45'>45cm </option>
                              <option value='46'>46cm </option>
                              <option value='47'>47cm </option>
                              <option value='48'>48cm </option>
                              <option value='49'>49cm </option>
                              <option value='50'>50cm </option>
                              <option value='51'>51cm </option>
                              <option value='52'>52cm </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="upload_normal_pdf">
                    <span className="image_selected_span">{this.state.pdfSelected}</span>
                      <div className="upload_pdf">
                          <h2>upload pdf design<img src={upload_pdf} /></h2>
                          <input type="file" id="upload_design2" accept="application/pdf" onChange={this.handleFileChange2} />
                      </div>
                    </div> */}
                    <div className="highlight normal_ad_design_upload">
                      <h5>HAVE YOUR OWN DESIGN? CLICK TO UPLOAD </h5> 
                      <div className="newsRight">
                        <span className="image_selected_span">{this.state.pdfSelected}</span>
                        <div class="wrapper">
                          <div class="file-upload">
                            <input type="file" id="upload_design" accept="image/png, image/gif, image/jpeg" onChange={this.handleFileChange2} />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                
                <div className="col-sm-6 classified_ad">
                  <div className="design_block_section">
                    <div id="dddd" className="block_section2 normal_width" style={{width: this.state.activeWidth}}>
                    <div className='normal_ui_1'>
                    {editor = <EditorJs
                          onChange={this.oneditorChange}
                          inlineToolbar={['bold', 'italic','underline','fontSize','Color']}
                          tools={EDITOR_JS_TOOLS}
                            enableReInitialize={false}
                            // tools={EDITOR_JS_TOOLS}
                            data={{
                              time: 1556098174501,
                              blocks: [
                                {
                                  type: "paragraph",
                                  data: {
                                    text:html,
                                  }
                                }
                              ]
                            }}
                         
                            />} 
                      </div>
                      {this.state.upload_design ? 
                        <img src={this.state.upload_design} alt="" />
                        :<img src={dummyImg} alt="" /> } 
                      <div className='normal_ui_2'>
                      {editor2 =   <EditorJs
                           onChange={this.oneditorChange2}
                           inlineToolbar={['bold', 'italic','underline','fontSize','Color']}
                          tools={EDITOR_JS_TOOLS}
                            enableReInitialize={false}
                            // tools={EDITOR_JS_TOOLS}
                            data={{
                              time: 1556098174501,
                              blocks: [
                                {
                                  type: "paragraph",
                                  data: {
                                    text:html2,
                                  }
                                }
                              ]
                            }}/>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 classified_ad">
                  <div className="uploading_section">
                    <div class="wrapper">
                      <h3>Upload Image</h3>
                      <div class="file-upload">
                      <input type="file" id="upload_design" accept="image/png, image/gif, image/jpeg" onChange={this.handleFileChange} />
                      </div>
                    </div>
                    <div className="uploading_notes">
                      <h2>Important Tips:</h2>
                      <p>Click on text section in ad layout to edit your text.</p>
                      <p>Use editor toolbar to enhance your ad matter.</p>
                      <p>Click on preview ad button to preview your actual ad.</p>
                      <p>Click on image to upload your image (if available in ad layout)</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 mt-5">
                 
                  <div className="newspaperList">
                    <h5>{this.state.newsPaperRecord} Rs {this.state.pricePera}/sqcm X {this.state.displayWidth}cm (W) X {this.state.displayHeight}cm (H)</h5> 
                    <div className="newsRight">
                      <h4>Rs {this.state.totalPrice}</h4>
                    </div>
                  </div> 

                  <div className="date_payment_btns">
                    <button type="submit" className="upload_design_btn">select dates & make payment</button>
                  </div> 
           
                </div>
            </div> 
          </form>
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  )}
}




