import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_3.png";
import {Helmet} from "react-helmet";
import swal from 'sweetalert';
import DocumentMeta from 'react-document-meta';

export default class BookingSummary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        paymentId: '',
        newspaperName : '',
        cityName : '',
        orderId: '',
        bookingID: this.props.location.state.bookingId,
        totalAmount: '',
        BannerImg:'',
        BannerContent:'',
        totalDays:'',
        metaKeyword: '',
        metaDescription: '',
        metaTitle: ''
       }
       this.openCheckout = this.openCheckout.bind(this);
       this.transactionVerification = this.transactionVerification.bind(this);
       this.handleSpecification = this.handleSpecification.bind(this);
    }

    componentDidMount(){
        axios({
        method: 'get',
        url: API_BASE_URL + 'papername/?bid='+this.state.bookingID
        }).then(response => { 
            //console.log("paper name",response.data)    
            this.setState({MerchantName : 'Times tribute'})
            this.setState({MerchantEmail : response.data.email})
            this.setState({newspaperName : response.data.paper_name})
            this.setState({cityName : response.data.city})
            this.setState({datesSelected : response.data.dates})
            const days_split = this.state.datesSelected.split(',')
            const dayLength = days_split.length
            this.setState({totalDays: dayLength})
           // console.log("date length :",this.state.totalDays)
            if(this.state.totalDays){
                axios({
                method: 'get',
                url: API_BASE_URL + 'order/?id='+this.state.bookingID+'&no='+this.state.totalDays
                }).then(response => { 
               // console.log("patch",response.data) 
                this.setState({orderId:response.data.id.id,
                totalAmount:parseFloat(response.data.id.amount).toFixed(2),
                GSTPercentage : response.data.gst,
                AmountWithoutGst : parseFloat(response.data.amount_without_gst).toFixed(2)}) 
                const gstApplied = (this.state.AmountWithoutGst * this.state.GSTPercentage)
                this.setState({gstAdded: parseFloat(gstApplied).toFixed(2)})
                }).catch((error) => {
                });
            }
        }).catch((error) => {
        });

        axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'booking_summary' } }).then(res=>{
        // console.log("dataaaaa-dgfffg-----",res.data)
            this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
            this.setState({BannerContent:res.data[0].description})
        })

        axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 15 }}).then(res=>{
           // console.log("dataaaaa------",res.data[0])
            this.setState({metaKeyword: res.data[0].meta_keyword,
            metaTitle:res.data[0].title,
            metaDescription:res.data[0].meta_description})
        })

    }

    handleSpecification=(evt)=>{
        this.setState({ specification: evt.target.value });
    }
    
    transactionVerification = (id,status)=>{

      //  console.log("booking id",this.state.bookingID)
       // console.log("status",status)

        axios({
        method: 'get',
        url: API_BASE_URL + 'razor/?bookingid='+this.state.bookingID+'&pid='+id
        }).then(response => { 
           //console.log("verifyy list",response) 
           if(status == "success"){
           const finalamount = this.state.totalAmount/100
           this.props.history.replace({ 
            pathname: '/transaction_detail',
            state: {  
                bookingId: this.state.bookingID,
                orderId: this.state.orderId,
                totalAmount: finalamount,
                publication: this.state.newspaperName,
                city: this.state.cityName
            }})

           }
        }).catch((error) => {
           // console.log("api error")
        });
    }

        openCheckout() {

            axios({
            method: 'patch',
            url: API_BASE_URL+'booking/',
            data: {
                bid : this.state.bookingID,
                special_request : this.state.specification
            }
            }).then(response => {
               // console.log("complete booking",response.data)
                if(response.data.msg == 'Booking Done Successfully'){
                  //  console.log("booking done")
                }else{
                  alert("something went wrong")
                }
            })
            .catch((error) => {
            // console.log(error)
            }); 

            let options = {
                
               "key": "rzp_live_xzJvWmmqp7sxa6",
              // "key": "rzp_test_CSD1OWv5bUjeBV",
               //"amount": (this.state.totalCostAdded), // 2000 paise = INR 20, amount in paisa
              "name": this.state.MerchantName,
              "description": "Payment For Add Promotion",
               // "image": "/your_logo.png",
              "order_id": this.state.orderId,
              "handler":  ((response)=>{
                // swal({
                //     text: "Your Payment is successful.",
                //     icon: "success",
                // })
                
                 this.transactionVerification(response.razorpay_payment_id,'success');
               
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
              }),
              "prefill": {
                "name": this.state.MerchantName,
                "email": this.state.MerchantEmail
              },
              "notes": {
                "address": "Hello World"
              },
              "theme": {
                "color": "#F37254"
              },
              "modal": {
                "ondismiss": function(){
                    swal({
                        text: "Your payment is cancelled.",
                        icon : "warning",
                        dangerMode: true,
                    })
                }
            }

        }  
         
            let rzp = new window.Razorpay(options);
            rzp.open();
            rzp.on('payment.failed', ( (response)=>{
                // swal({
                //     text: "Your payment is cancelled.",
                //     // icon : "warning",
                //     dangerMode: true,
                // })

                 this.transactionVerification(response.error.metadata.payment_id,'failed');
              
                // console.log("cancel",response)
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                //  console.log("paymentId",response.error.metadata.payment_id);
                //  this.setState({paymentId: response.error.metadata.payment_id})
                // this.transactionVerification()

            // if(response.error.reason === 'payment_cancelled'){
             
            // }
               
              })) ;

          }

         
render() {

    const meta = {
        title: this.state.metaTitle,
        description: this.state.metaDescription,
        meta: {
            charset: 'utf-8',
            name: {
            keywords: this.state.metaKeyword
            }
        }
    };

  return (
<DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    
    <Helmet>
    <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
    </Helmet>

    <section className="Booking_step1 commonPg">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2>Compose Ads</h2>
                        <h2 className="active">Dates & Payment</h2>
                    </div>
                </div>
               
                <div className="col-sm-12">
                    <div className="commonHead">
                        <h2>Booking Summary</h2>
                        <div className="dash"></div>
                    </div>
                    <p className="summary_text">Please verify your add booking details before proceeding to payment.</p>
                </div>
            </div>
          
            <div className="row">
                {/* <div className="col-sm-12">
                    <div className="cat_text_content">
                       <div className="combine_ad">
                            <div className="ad_input_bg">
                                <h3>Ad Category</h3>
                            </div>
                            <div className="summary_inputs border_upper">
                                <input type="text" value={this.state.category}  onChange={this.handleCategory} placeholder="Property, Real Estate,"/>
                            </div>    
                       </div>
                       <div className="combine_ad">
                            <div className="ad_input_bg">
                                <h3>Ad Text</h3>
                                <p>(click to edit)</p>
                            </div>
                            <div className="summary_inputs">
                                <input type="text" value={this.state.adText}  onChange={this.handleAdText} placeholder="e, between words while  composing your ad"/>
                            </div>    
                       </div>
                    </div>
                </div> */}

                <div className="col-sm-12">
                    <div className="instruction_box">
                        <h3>Any Specifications / Request ?</h3>
                        <textarea type="text" value={this.state.specification}  onChange={this.handleSpecification} placeholder="Specific Instructions"></textarea>
                    </div>
                </div>

                <div className="col-sm-12 payment-sec">
                    <div className="commonHead">
                        <h2>Payment Method</h2>
                        <div className="dash"></div>
                    </div>
                    <div className="table-container">
                    <table class="table table-bordered custom_table">
                        <thead>
                          <tr>
                            <th scope="col">Newspaper-Location</th>
                            <th scope="col">Dates</th>
                            <th scope="col">Total Cost</th>
                           </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{this.state.newspaperName} ({this.state.cityName})</th>
                                <td>{this.state.datesSelected}</td>
                                <td>
                                    {/* <div className="cost_field">
                                        <span>Charges</span>
                                        <span>1</span>
                                    </div>
                                    <div className="cost_field">
                                        <span>Basic Rates</span>
                                        <span>10000</span>
                                    </div>
                                    <div className="cost_field">
                                        <span>Total lines</span>
                                        <span>2</span>
                                    </div> */}
                                    <div className="cost_field">
                                        <span>Total Amount</span>
                                        <span>Rs. {parseFloat(this.state.AmountWithoutGst).toFixed(2)}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>GST {this.state.GSTPercentage}%</td>
                                <td>
                                <div className="cost_field">
                                    <span></span>
                                    <span>Rs. {parseFloat(this.state.gstAdded/100).toFixed(2)}</span>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td>Total Ad Cost</td>
                                <td>
                                <div className="cost_field">
                                    <span></span>
                                    <span>Rs. {parseFloat(this.state.totalAmount/100).toFixed(2)}</span>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className="col-sm-12 make_payment text-center">
                    <button onClick={this.openCheckout}>Make Payment</button>
                </div>

            </div> 

        </div>
    </section>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta> 
  )}
}


