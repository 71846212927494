import React from "react";
import logo from '../../assets/img/logos/logo.png'
import phone from '../../assets/img/icons/call.png'
import {NavLink} from 'react-router-dom'
import { withRouter } from 'react-router';


class CommonHeader extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {}
    this.adNav = this.adNav.bind(this)
  }

  adNav(){
   // alert("hii")
    this.props.history.replace({ 
     pathname: '/step1',
     state: {  
       id:'',
       id1:'',
       id2:'',
       id3:''
     }})
   }
 
render() {

return (
<header className="commonHeader"> 
<div className="Header-top">
 <div className="container">
   <div className="row call_info_padding">
     <div className="col-sm-6 m-auto">
      <NavLink to="/"> <img src={logo} alt="" /></NavLink>
     </div>
     <div className="col-sm-6 call_info">
       <p>Call Now</p>
       <h1><a className="whatsp_link" href="tel:9810506092"><img src={phone} alt="" />+91-9810506092 </a></h1>
     </div>
   </div>
 </div>
</div>
<div className="Header-bottom">
 <div className="container"> 
   <nav className="navbar navbar-expand-lg custom_navbar">
     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
       <span className="navbar-toggler-icon"></span>
     </button>
     <div className="collapse navbar-collapse" id="navbarSupportedContent">
       <ul className="navbar-nav mr-auto">
         <li className="nav-item">
          <NavLink exact activeClassName="active" className="nav-link" to="/">Home </NavLink>
         </li>
         <li className="nav-item">
           <NavLink  activeClassName="active" className="nav-link" to="/obituary">Obituary </NavLink>
         </li>
         <li className="nav-item">
           <NavLink  activeClassName="active" className="nav-link" to="/remembrance">remembrance </NavLink>
         </li>
         <li className="nav-item">
           <NavLink  activeClassName="active" className="nav-link" to="/real_heros">tribute to real heroes </NavLink>
         </li>
         <li className="nav-item">
           <NavLink  activeClassName="active" className="nav-link" to="/celebrity">celebrity remembrance </NavLink>
         </li>
         <li className="nav-item">
           {/* <NavLink  activeClassName="active" className="nav-link" to="/step1">Ad in newspaper </NavLink> */}
           <a className="nav-link cursor-pointer" onClick={this.adNav}>Ad in newspaper </a>
         </li>
         <li className="nav-item">
           <NavLink  activeClassName="active" className="nav-link" to="/about">About Us</NavLink>
         </li>
         <li className="nav-item">
           <NavLink  activeClassName="active" className="nav-link" to="/contact">Contact </NavLink>
         </li>
       </ul>
     </div>
   </nav>
 </div>
</div>
</header>

)}
}


export default withRouter(CommonHeader);