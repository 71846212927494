import React from "react";
import CommonLogoSlider from "../Common/commonLogoSlider";
// import CommonBanner from "../Common/commonBanner";
import CommonHeader from '../Common/commonHeader'
import EnquiryForm from "../Common/enquiryform";
import Footer from '../Common/footer'
import axios from 'axios';
import { API_BASE_URL ,IMG_BASE_URL } from '../../config/api';
import stepBackground from "../../assets/img/step_2.png";
import upload_pdf from  '../../assets/img/icons/upload_img.png';
import validator from 'validator'
import DocumentMeta from 'react-document-meta';

var bgPrice = 0
var borderPrice = 0
var linesCost = 0

export default class MatterDesign extends React.Component {

    constructor (props) {
        super (props)
        this.state = {
          offerId: this.props.location.state.offerIdParam,
          publicationId: this.props.location.state.publicationIdParam,
          cityId: this.props.location.state.cityIdParam,
          adtypeId: this.props.location.state.adtypeIdParam,
          ContactName: '',
          ContactMobile: '',
          ContactEmail: '',
          RateList: [],
          subcat:[],
          Subcategory : '',
          Preferences : '',
          matter:'',
          price:'',
          city_name:'',
          paper_name:'',
          setbgOffer : '',
          setborderOffer : '',
          CharCount:'',
          // activeClassBg: '',
          upload_design: null,
          setTotalCalculation: '',
          BannerImg:'',
          BannerContent:'',
          lineUsed: 0,
          backgroundColors:[],
          screenBorder:'0',
          setExtraCharUsed: '0',
          setExtraLinesUsed: '0',
          metaKeyword: '',
          metaDescription: '',
          metaTitle: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onBgChange = this.onBgChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
      }

      handleChangeName = (evt) =>{
        this.setState({ ContactName: evt.target.value });
      }
      handleChangeMobile= (evt) =>{
          this.setState({ ContactMobile: evt.target.value });
      }
      handleChangeEmail= (evt) =>{
        this.setState({ ContactEmail: evt.target.value });
      }

      handlePreference = (evt) =>{
        this.setState({ Preferences: evt.target.value });
      }

      handleSubcategory = (evt) =>{
        this.setState({ Subcategory: evt.target.value });
      }

      componentDidMount(){

        axios.get(API_BASE_URL+`subcategory/`).then(res => {
         // console.log("response",res.data)
          this.setState({subcat: res.data})
        })

        axios({
        method: 'get',
        url: API_BASE_URL + 'basicrate/?offerid='+this.state.offerId
        }).then(response => { 
           // console.log("basic rates",response.data) 
            this.setState({setbgOffer:response.data.rate[0].colors_extra}) 
            this.setState({setborderOffer:response.data.rate[0].border_extra}) 
            this.setState({backgroundColors:response.data.colorsname}) 
            this.setState({linesFormat:response.data.rate[0].lines}) 
            this.setState({per_lines:response.data.rate[0].text_char}) 
            this.setState({text_per_LinesChars:response.data.rate[0].text_per_char}) 
            this.setState({price:response.data.rate[0].text_price})
            this.setState({minTextChar: response.data.rate[0].text_char}) 
           // const costing = response.data.rate[0].text_price * response.data.rate[0].text_char
            this.setState({setTotalCalculation: response.data.rate[0].text_price})
            this.setState({linesRate: response.data.rate[0].text_price}) 
            this.setState({city_name:response.data.rate[0].city.city_name}) 
            this.setState({paper_name:response.data.rate[0].publication.paper_name}) 
        }).catch((error) => {
        });

        axios.get(API_BASE_URL+`banner/`,{ params: { bannername: 'matter_design' } }).then(res=>{
          // console.log("dataaaaa-dgfffg-----",res.data)
           this.setState({BannerImg:IMG_BASE_URL+res.data[0].image})
           this.setState({BannerContent:res.data[0].description})
         })

         axios.get(API_BASE_URL+`Meta_data_list/`,{ params : { page_id : 19 }}).then(res=>{
          // console.log("dataaaaa------",res.data[0])
          this.setState({metaKeyword: res.data[0].meta_keyword,
          metaTitle:res.data[0].title,
          metaDescription:res.data[0].meta_description})
        })

      }

      handlematter = (evt) =>{
        evt.preventDefault();
        //for words 
        let charCount
        if(evt.target.value.length == 0){
           charCount = 0
        }else{
           charCount = (evt.target.value.split(" ").length);
          // console.log("wordsss",charCount)
        }
      
       // let charCount = evt.target.value.length
        this.setState({CharCount : charCount})
        this.setState({ matter: evt.target.value });
        const el =  document.getElementById('content');
        const divHeight = el.offsetHeight
        const lineheight = parseInt(divHeight/20) 
        let totalamount
        if(this.state.linesFormat == 1){
          this.setState({lineUsed : lineheight})
          if(lineheight > this.state.minTextChar){
              let linesCount = lineheight-this.state.minTextChar
              // console.log("count value lines =",linesCount);
              this.setState({setExtraLinesUsed:linesCount})
              totalamount =  (linesCount * this.state.text_per_LinesChars) + Number(this.state.price)
              this.setState({linesRate: totalamount})
              linesCost =  totalamount
          }else{
            this.setState({setExtraLinesUsed: 0})
            totalamount =  (this.state.setExtraLinesUsed * this.state.text_per_LinesChars) + Number(this.state.price)
            this.setState({linesRate: totalamount})
            linesCost =  totalamount
          }
        }else{
          this.setState({lineUsed : charCount})
          if(charCount > this.state.minTextChar){ 
              let linesCount = charCount-this.state.minTextChar
             // console.log("count value chars =",linesCount);
              this.setState({setExtraCharUsed:linesCount})
              totalamount =  (linesCount * this.state.text_per_LinesChars) + Number(this.state.price)
              this.setState({linesRate: totalamount})
              linesCost =  totalamount
          }else{
            //console.log("char less price = ",charCount)
            this.setState({setExtraCharUsed: 0})
            totalamount =  (this.state.setExtraCharUsed * this.state.text_per_LinesChars) + Number(this.state.price)
            this.setState({linesRate: totalamount})
            linesCost =  totalamount
          }
        }
        if(this.state.backColor){
          if(this.state.backColor != '#fff'){
            bgPrice = (this.state.setbgOffer * linesCost)/100
          }
        }

        if(this.state.screenBorder == '1'){
         // this.setState({screenBorder: e.target.value})
          if(linesCost){
            borderPrice = (linesCost * this.state.setborderOffer)/100
            //  console.log('borderp',borderPrice)
          }else{
            borderPrice = (this.state.price * this.state.setborderOffer)/100
          }
      
        }else{
            borderPrice = 0
        }
        this.TotalCalculation()
      }

      onBgChange=(e)=>{
         this.setState({backColor: e.target.value})
         
        if(e.target.value == '#fff'){
          bgPrice = 0
        }else{
          if(linesCost){
            bgPrice = (this.state.setbgOffer * linesCost)/100
           // console.log('bg',bgPrice)
          }else{
            bgPrice = (this.state.setbgOffer * this.state.price)/100
           // console.log('bg',bgPrice)
          }
        }
          this.TotalCalculation()
      }

      onBorderchnage = (e) =>{
        //console.log("dkjfhdfghsdi",e.target.value)
        if(e.target.value == '1'){
            this.setState({screenBorder: e.target.value})
            if(linesCost){
              borderPrice = (linesCost * this.state.setborderOffer)/100
            }else{
              borderPrice = (this.state.price * this.state.setborderOffer)/100
            }
        }else{
            borderPrice = 0
        }
        this.TotalCalculation()
      }

      TotalCalculation = () =>{
        if(linesCost){
          const totalcost = Number(bgPrice) + Number(borderPrice) + Number(linesCost)
        // console.log("total",totalcost)
          this.setState({setTotalCalculation: parseFloat(totalcost).toFixed(2)})
        }else{
          const totalcost = Number(bgPrice) + Number(borderPrice) + Number(this.state.price)
        // console.log("total",totalcost)
          this.setState({setTotalCalculation: parseFloat(totalcost).toFixed(2)})
        }
      }

      handleFileChange(evt) {
        this.setState({upload_design: evt.target.files[0]})
       // console.log("hello",evt.target.files[0].name)
        this.setState({pdfSelected: evt.target.files[0].name})
      }

      validationform =()=> {
        let isvalid = true
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (validator.isEmail(this.state.ContactEmail)) {
          this.setState({emailerror:false})
        }else{ 
          this.setState({emailerror:true})
          isvalid=false
        }
    
        if(!isNaN(this.state.ContactMobile) ){
          if(this.state.ContactMobile.length<9){
            this.setState({moberror:'please enter 10 digit mobile number'})
          }
          if( this.state.ContactMobile.length===10 ){
            this.setState({moberror:''})
          }
        }else if (isNaN(this.state.ContactMobile)){
          this.setState({moberror:'Mobile number is not valid'})
          isvalid=false
         }
         if(this.state.ContactMobile ===""){
          this.setState({moberror:'Please fill you mobile number'})
          isvalid=false
        }

        if (this.state.ContactMobile !== "undefined") {

          var pattern = new RegExp(/^[0-9\b]+$/);
        
          if (!pattern.test(this.state.ContactMobile)) {
        
            isvalid = false;
            this.setState({moberror:'Please enter only number.'})
           // errors["phone"] = "Please enter only number.";
        
          }else if(this.state.ContactMobile.length != 10){
        
            isvalid = false;
            this.setState({moberror:'Please enter valid phone number.'})
            //errors["phone"] = "Please enter valid phone number.";
        
          }
        
        }

        return isvalid 
      }
      
      handleSubmit = (event)=>{
    
        event.preventDefault();
        if(this.validationform()){
        const formData = new FormData();
        if(this.state.upload_design){
            formData.append("pdf_file",this.state.upload_design);
        }
        formData.append("offer" , this.state.offerId);
        formData.append("publication", this.state.publicationId);
        formData.append("city", this.state.cityId);
        formData.append("adtype", this.state.adtypeId);
        formData.append("types_of_ad", '1');
        formData.append("contact_name", this.state.ContactName);
        formData.append("contact_email", this.state.ContactEmail);
        formData.append("contact_mobile", this.state.ContactMobile);
        formData.append("preferences", this.state.Preferences);
        formData.append("subcategory", this.state.Subcategory);
        formData.append("matter", this.state.matter);
        formData.append("total_amt", this.state.setTotalCalculation);
        formData.append("screen_border", this.state.screenBorder); 
        if(this.state.screenBorder != '0'){
          formData.append("screen_border_extra", this.state.setborderOffer);
        }
        formData.append("back_color", this.state.backColor);
        if(this.state.backColor != '#fff'){
          formData.append("back_color_extra", this.state.setbgOffer);
        }
        formData.append("no_of_lines", this.state.lineUsed);
        
        axios({
          method: 'post',
          url: API_BASE_URL+'booking/',
          data: formData
         }).then(response => {
         // console.log(this.state.publicationId,this.state.cityId,this.state.adtypeId)
         // console.log("post",response.data)

          if( response.data.msg == 'Booking Done Successfully'){
            this.props.history.push({ 
              pathname: '/step3',
              state: {bookingId: response.data.data1.id}})
          }
          else{
            alert('something went wrong');
          }

        })
          .catch((error) => {
          // console.log(error)
          });
        }
      }

render() {

  const meta = {
    title: this.state.metaTitle,
    description: this.state.metaDescription,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: this.state.metaKeyword
      }
    }
  };

  const Adsubcategory = this.state.subcat.map((data)=>{
    return <option key={data.id} value = {data.name}>{data.name}</option>
  })

  return (
  <DocumentMeta {...meta}>
  <main className="mainBg">
    <CommonHeader></CommonHeader>
    <section className="obituary_section" style={{backgroundImage: "url("+this.state.BannerImg+")"}}>
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="inner_heading">
              {/* <p>{this.state.BannerContent}</p> */}
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </section>
    <section className="Booking_step1 commonPg">
        <div className="container">
        <form autocomplete="off" onSubmit={this.handleSubmit}>
            <div className="row">
                <div className="col-sm-12">
                    <div className="stepBG" style={{backgroundImage: "url(" + stepBackground + ")"}}></div>
                    <div className="stepsHead">
                        <h2>Select Newspaper</h2>
                        <h2 className="active">Compose Ads</h2>
                        <h2>Dates & Payment</h2>
                    </div>
                </div>
                
                <div className="col-sm-12">
                   
                    <div className="contact-form mb-5">
                        <div className="row">
                            <div className="col-sm-4">
                                <div class="form-group custom_fields">
                                    <label for="name">Name</label><br/>
                                    <input type="text" name="name" value={this.state.ContactName}  onChange={this.handleChangeName} placeholder="Name" required/> 
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div class="form-group custom_fields">
                                    <label for="mobile">Mobile</label><br/>
                                    <input type="text" maxlength="10" minlength="10"  name="Mobile" value={this.state.ContactMobile} onChange={this.handleChangeMobile} placeholder="Mobile" required/> 
                                    {this.state.moberror?<p className="validationAlert" >Please enter valid number</p>:null} 
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div class="form-group custom_fields">
                                    <label for="email">Email</label><br/>
                                    <input type="text" name="email" value={this.state.ContactEmail} onChange={this.handleChangeEmail} placeholder="Email" required/> 
                                    {this.state.emailerror?<p className="validationAlert" >Please enter valid email</p>:null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form">
                        <div className="row">
                            <div className="col-sm-6">
                                <div class="form-group custom_fields">
                                <label for="subcategory">Subcategory</label><br/>
                                    <select 
                                        name="subcategory"
                                        onChange = {this.handleSubcategory}
                                        required="required">
                                        <option value='Select Sub-Category'>Select Sub-Category</option>
                                       {/* <option value='Agents & agencies'>Agents & agencies</option>
                                        <option value='Business Offers'>Business Offers</option>
                                        <option value='Business Offers & Proposals'>Business Offers & Proposals</option>
                                        <option value='Consultancy'>Consultancy</option>
                                        <option value='Machinery & eqipments'>Machinery & eqipments</option>
                                        <option value='Miscellaneous'>Miscellaneous</option> */}
                                        {Adsubcategory}
                                    </select>

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div class="form-group custom_fields">
                                    <label for="newspaper">Preference</label><br/>
                                    <textarea onChange={this.handlePreference} placeholder="Any Preference ? Share it here" rows="1" required="required">
                                  </textarea>
                                </div>
                            </div>
                            {/* <div className="col-sm-12">
                                <p className="note_matter">Hindustan Times : Please keep space between two words while composing your ad.</p>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="col-sm-9">
                    <div className="subcat_pref_box">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Compose Ad Matter</h2>
                                <textarea onChange={this.handlematter} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} placeholder="Start Typing Your Ad here..." rows="5" required="required"></textarea>
                                <div className="matter-cost">
                                  {/* {this.state.linesFormat == 0 ?
                                  <h5>Total Characters used : <span>{this.state.setExtraCharUsed}</span></h5>
                                  :<h5>Total lines used : <span>{this.state.setExtraLinesUsed}</span></h5>
                                  } */}
                                  {this.state.linesFormat == 0 ?
                                  <h5>Total words used : <span>{this.state.lineUsed}</span></h5>
                                  :<h5>Total lines used : <span>{this.state.lineUsed}</span></h5>
                                  }
                                  <h5>Estimate Cost : <span>Rs. {this.state.linesRate}</span></h5>

                                  {/* {this.state.linesRate ? 
                                    <h5>Estimate Cost : <span>Rs. {this.state.linesRate}</span></h5>
                                    :<h5>Estimate Cost : <span>Rs. {this.state.price}</span></h5>
                                  } */}
                                </div>
                                <p className="note_matter">{this.state.paper_name} : Please keep space between two words while composing your ad.</p>
                            </div>
                            {this.state.setbgOffer && this.state.setborderOffer !== '0' ?
                            <div className="col-sm-12">
                                <div className="subcat_pref_box">
                                    <h2>Ad Enhancements </h2>
                                    {this.state.setbgOffer !== '0' ?
                                    <>
                                    <div className="matter-cost">
                                        <h5>Background color : <span>({this.state.setbgOffer}% extra)</span></h5>
                                    </div>
                                    <div className="select_bg">
                                      {this.state.backgroundColors.map((data,index)=>
                                      <>
                                        <input id={index} name="bgcolors" type='radio' value={data.color_code} onChange={this.onBgChange}></input>
                                        <label for={index} className='bg_colors' style={{background : data.color_code}}></label> 
                                      </>
                                      )}
                                      <input id={100} name="bgcolors" type='radio' value={'#fff'} onChange={this.onBgChange}></input>
                                      <label for="100" className='no_bg_text'>No Background</label>
                                    </div>
                                    </> : null }
                                    {this.state.setborderOffer !== '0' ?
                                    <>
                                    <div className="matter-cost">
                                      <h5>Screen Border : <span>({this.state.setborderOffer}% extra)</span></h5>
                                    </div> 
                                    <div className="screen_border_div">
                                        <div className="borderdiv1">
                                            <label for="colorad">yes</label>
                                            <input type="radio" id="colorad" name="colormode" onChange={this.onBorderchnage} value="1"/>
                                        </div>
                                        <div className="borderdiv1">
                                            <label for="bwadd">no</label>
                                            <input type="radio" id="bwadd" name="colormode" onChange={this.onBorderchnage} value="0"/>
                                        </div>
                                    </div> 
                                    </>
                                    :null }
                                </div>
                            </div> : null }

                            <div className="col-sm-12">
                                <div className="subcat_pref_box">
                                    <h2>Upload Your Pdf </h2>
                                    <div className="upload_pdf mb-2">
                                        <h2>upload pdf <img src={upload_pdf} /></h2>
                                        <input type="file" id="upload_design" accept="application/pdf" onChange={this.handleFileChange} />
                                    </div>
                                    <span className="image_selected_span">{this.state.pdfSelected}</span>
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="subcat_pref_box">
                                    <h2>Basics Rates</h2>
                                    <div className="calculated_rates">
                                        <h3>{this.state.paper_name} ({this.state.city_name})</h3>
                                        <span>Rs. {this.state.setTotalCalculation}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-sm-3">
                   <div className="preview_container">
                     <div className="preview_header">
                        <h2>Ad Preview</h2>
                     </div>
                      <div className="preview-box">
                        <p className={"preview-pera "+(this.state.screenBorder == '1'  ? 'screen-border2' : 'no_border')} id="content" style={{background: this.state.backColor}}>{this.state.matter}</p>
                        
                      </div>
                      <div className="bottom_note_preview">
                        <p>Font size in the preview is not as per scale. Actual word/lines may vary based on selected enhancements</p>
                      </div>
                   </div>
                </div>

                <div className="col-sm-12">
                  <div className="date_payment_btns">
                   
                     <button type="submit" className="upload_design_btn">Select Dates & Make Payment</button>
                   
                  </div>
                </div>
                
            </div> 
        </form>
        </div>
    </section>
    <EnquiryForm></EnquiryForm>
    <CommonLogoSlider></CommonLogoSlider>
    <Footer></Footer>
  </main>  
  </DocumentMeta> 
  )}
}





